import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { getCartItems, removeItemsFromCart, updateProductQuantityInCart } from '../../actions/cartAction';
import MetaData from '../MetaData';
import defaultImg from "../../assets/images/defaultImage.jpg";
import { Tooltip } from '@mui/material';
import { formatCurrency, slugify } from '../../utils/functions';
import ConfirmationModal from '../Other/ConfirmationModal';

const CartItem = (props) => {

  const { 
    product_id, 
    product_img1, 
    product_name, 
    product_new_price, 
    qty, 
    stock,
  } = props;


  const {inCart} = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()

  
  const {isAuthenticated, user} = useSelector(state => state.user)

  const [open, setOpen] = useState(false);

  if(!isAuthenticated){
    navigate("/login")
  }
  useEffect(() => {
    if (isAuthenticated && user?.id) { // Check if user exists and has an id
      const userId = user.id;
      dispatch(getCartItems(userId));
    }
    window.scrollTo(0,0)
    
  }, [dispatch, isAuthenticated, user?.id])
  
  const handleIncreaseQuantity = async () => {
    // const stock = 12;
    const newQuantity = parseInt(qty) + 1;
  
    if (newQuantity > 5) {
      enqueueSnackbar('You can not order more than 5 quantities', { variant: 'warning' });
      return;
    }
  
    if (newQuantity > stock) {
      enqueueSnackbar('Quantity reached maximum stock', { variant: 'warning' });
      return;
    }
  
    try {
      await dispatch(updateProductQuantityInCart(user.id, product_id, newQuantity));
      await dispatch(getCartItems(user.id)); // Fetch latest cart items
    } catch (error) {
      enqueueSnackbar('Failed to update cart. Please try again.', { variant: 'error' });
    }
  };

  const handleDecreaseQuantity = async () => {
    const newQuantity = parseInt(qty) - 1;
  
    if (newQuantity < 1) {
      enqueueSnackbar('Quantity should not be less than 1', { variant: 'warning' });
      return;
    }
    try {
      await dispatch(updateProductQuantityInCart(user.id, product_id, newQuantity));
      await dispatch(getCartItems(user.id)); // Fetch latest cart items
    } catch (error) {
      enqueueSnackbar('Failed to update cart. Please try again.', { variant: 'error' });
    }
  };
  

  const handleOpenDialog = () => {
    setOpen(true)
  }
  const handleCloseDialog = () => {
    setOpen(false)
  }

  const removeCartItem = async() => {
    await dispatch(removeItemsFromCart(user.id, product_id))
    await dispatch(getCartItems(user.id))
    // enqueueSnackbar('Item removed from cart', {variant:"success"})
    handleCloseDialog();
  }


  return (

    <div className="bg-white shadow-md rounded-lg  overflow-x-auto">
      <MetaData title="Shopping Cart" />
      <div className="space-y-4">
        {/* Cart Item */}
        <div className="flex items-center p-4 border-b">
          <div className="w-24 h-24 flex-shrink-0">
            <Link to={`/products/${slugify(product_name)}/${product_id}/buy`}>
              <img 
                src={product_img1} 
                alt={product_name}
                loading='lazy'
                className="w-full h-full object-cover" 
                title={product_name}
                onError={(e) => {
                  e.target.src = defaultImg
                }}
              />
            </Link>
          </div>
          <div className="ml-4 flex-grow">
            <Link to={`/products/${slugify(product_name)}/${product_id}/buy`} className="text-lg font-semibold text-gray-800 hover:text-primary-blue">
              {product_name.length > 42 ? `${product_name.substring(0, 42)}...` : product_name}
            </Link>
            <div className="flex items-center gap-2 text-lg font-medium">
              <span className="text-primary-blue">{formatCurrency(product_new_price * qty) }</span>
              <span className="text-sm text-gray-500 line-through decoration-2 font-normal">{formatCurrency(product_new_price * qty * 1.2) }</span>
            </div>
            <div className="flex items-center gap-2 mt-2">
              <button
                name='decrease-quantity'
                onClick={handleDecreaseQuantity}
                className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer hover:bg-gray-300"
              >
                <Tooltip title="Decrease Quantity" arrow>
                  <div>
                    -
                  </div>
                </Tooltip>
              </button>
              <input
                className="w-16 border outline-none text-center rounded-md py-1 text-gray-700 font-medium text-lg qtyInput"
                name='quantity'
                value={qty}
                disabled
              />
              <button
                name='increase-quantity'
                onClick={handleIncreaseQuantity}
                className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer hover:bg-gray-300"
              >
                <Tooltip title="Increase Quantity" arrow>
                  <div>
                    +
                  </div>
                </Tooltip>
              </button>
            </div>
          </div>
          {inCart && (
            <button onClick={handleOpenDialog} name='remove-item' className="text-gray-400 hover:text-red-500">
              <Tooltip title="Remove Item" arrow>
                <div>
                  <Delete/>
                </div>
              </Tooltip>
            </button>
          )}
        </div>
      </div>

      <ConfirmationModal 
        showModal={open} 
        handleClose={handleCloseDialog} 
        handleConfirm={removeCartItem} 
        message="Are you sure you want to delete this item ?"
      />
    </div>

  )
}

export default CartItem;
