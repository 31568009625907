import React from 'react';
import {Helmet} from "react-helmet"

const MetaData = ({title, description, url, image, canonical, keywords }) => {
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta property='og:title' content={title} />
      <meta property="og:description" content={description} />
      <meta property='og:type' content='website' />
      {url && <meta property='og:url' content={url} />}
      {image && <meta property='og:image' content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      {canonical && <link rel="canonical" href={canonical} />}
      <script type='application/ld+json'>{`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Trustyindian",
          "url": "https://www.trustyindian.com",
          "logo": "${image}",
          "sameAs": [
            "https://www.facebook.com/Trustyindian",
            "https://twitter.com/Trustyindian"
          ]
        }
      `}</script>
    </Helmet>
  )
}

export default MetaData;
