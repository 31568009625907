import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { clearErrors } from '../../actions/userAction';
import MetaData from '../MetaData';
import { FormControl, FormControlLabel, Radio, RadioGroup, } from '@mui/material';
import PriceSidebar from './PriceSidebar';
import { newOrder } from '../../actions/orderAction';
import { useNavigate } from 'react-router-dom';
import OrderConfirmation from '../Order/OrderConfirmation';
import Stepper from './Stepper';
import { getShippingAddress } from '../../actions/shippingAction';
import { getCartItems } from '../../actions/cartAction';
import { calculateTotalAmount } from '../../utils/functions';
import { resetPromoCode } from '../../actions/promoAction';

const Payment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [confirmingOrder, setConfirmingOrder] = useState(false);

    const [orderConfirmationOpen, setOrderConfirmationOpen] = useState(false);
    
    const { cartData } = useSelector(state => state.cart);
    const { isAuthenticated, user  } = useSelector(state => state.user);
    const { error, } = useSelector(state => state.orders);
    const { taxPercentage } = useSelector(state => state.orders.priceBar);
    const { deliveryDetails } = useSelector(state => state.shippingAddress)
    const { promoCode, discount, } = useSelector(state => state.promo)

    const deliveryAddress = JSON.parse(localStorage.getItem('deliveryDetails'))

    if(!isAuthenticated){
        navigate("/login")
    }

    useEffect(() => {
        if(error){
            dispatch(clearErrors())
            enqueueSnackbar(error, {variant: "error"})
        }
        if(isAuthenticated && user?.id){
            const userId = user.id;
            dispatch(getShippingAddress(userId))
        }
        window.scrollTo(0,0)
    }, [dispatch, error, enqueueSnackbar, isAuthenticated, user?.id])





    const discountPer = promoCode && discount ? discount : 0 ;

    const totalPrice = cartData && cartData.length > 0 && cartData.reduce((sum, item) => sum + (parseFloat(item.product_new_price) * parseInt(item.qty)), 0);
    
    const discountedPrice = totalPrice - (totalPrice * (discountPer / 100))
    const totalAmount = calculateTotalAmount(discountedPrice, taxPercentage).toFixed(2);
    
    const promo_code = promoCode ? promoCode : null;

    const shippingAddress = deliveryAddress && deliveryAddress?.address ? deliveryAddress.address : deliveryDetails.address;
    const mobileNo = deliveryAddress && deliveryAddress?.mobile_no ? deliveryAddress.mobile_no : deliveryDetails.mobileNo;

    const handleConfirmOrder = async() => {
        setConfirmingOrder(true);
        try{

            //Place order
            await dispatch(newOrder(user.id, paymentMethod, shippingAddress, promo_code, mobileNo, cartData, totalAmount ))
            await dispatch(getCartItems(user.id))
            dispatch(clearErrors())
            // enqueueSnackbar('Order placed successfully', { variant: 'success' });
            
            //Reset promo code after order is placed
            await dispatch(resetPromoCode())

            setOrderConfirmationOpen(true)
        }catch(error){
            console.log("Error placing order:", error);
            enqueueSnackbar('Failed to place order',error, { variant: 'error' });
        }finally {
            setConfirmingOrder(false)
        }
    }

    const handleOrderConfirmationClose = async() => {
        try
        {
            await setOrderConfirmationOpen(false)
            await navigate("/")
        }catch(error){
            console.log("Error while redirecting to the order page: ",error);
            enqueueSnackbar('Error while redirecting to the order page:',error, { variant: 'error' });
        }
    }
    

  return (
    <div>
        <MetaData title="Secure Payment" />
        <main className='max-w-8xl mx-auto pt-0 px-2 sm:px-4 lg:px-6 py-4'>
            <div className='bg-gray-100 rounded-lg shadow-lg p-3 bg flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7'>
                <div className='flex-1'>
                    <Stepper activeStep={3} className="bg-transparent">
                        <div>
                            <form autoComplete='off' className="flex flex-col justify-start gap-2 mx-8 my-4 overflow-hidden">
                                {/* <p className="text-gray-600 ">Currently, We accepting only Cash on Delivery.</p> */}
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        value={paymentMethod}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    >
                                        <FormControlLabel value="COD" control={<Radio />} label="Cash on Delivery" />
                                        <FormControlLabel value="Pay Online at Delivery time" control={<Radio />} label="Pay Online at Delivery time" />
                                    </RadioGroup>

                                </FormControl>

                                <button
                                    name='confirm-order'
                                    onClick={handleConfirmOrder}
                                    disabled={confirmingOrder}
                                    className={`bg-green-500 text-white px-6 mt-2 py-3 font-bold rounded-md hover:bg-green-600 transition-colors duration-300`}
                                >
                                    {confirmingOrder ? "Confirming Order..." : "Confirm Order"}
                                </button>
                            </form>
                        </div>
                    </Stepper>
                </div>
                <PriceSidebar cartItems={cartData} />
            </div>
        </main>

        {/* Order Confirmation */}
        <OrderConfirmation open={orderConfirmationOpen} onClose={handleOrderConfirmationClose} />
    </div>

    
  )
}

export default Payment
