import axios from "axios";
import { CLEAR_ERRORS, GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGOUT_USER_FAIL, LOGOUT_USER_SUCCESS,  } from "../constants/userConstants";


const API = process.env.REACT_APP_API_URL;

//define a function to check if the user is logging in for the first time
const isFirstLogin = () => {
    return sessionStorage.getItem('firstLogin') === null;
}


// Google login action creator
export const googleLogin = (userData) => async(dispatch, getState) => {
    try{
        
        dispatch({type: GOOGLE_LOGIN_REQUEST})

        if(userData){
            const {data} = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userData.access_token}`, {
                headers:{
                    Authorization: `Bearer ${userData.access_token}`,
                    Accept: "application/json"
                }
            })

            localStorage.setItem('token', JSON.stringify(userData.access_token) )
            sessionStorage.setItem('token', JSON.stringify(userData.access_token));

            //Check if this is first login
            if(isFirstLogin()){
                sessionStorage.setItem('firstLogin', 'true')
            }

            
            localStorage.setItem('loginMethod', 'google')
            localStorage.setItem('id', JSON.stringify(data.id) )
            sessionStorage.setItem('id', JSON.stringify(data.id));
            localStorage.setItem('userData',JSON.stringify(data));
            sessionStorage.setItem('userData', JSON.stringify(data))
            

            const postData = {
                operation:"validateUserLogin",
                uid: data.id,
                username: data.given_name,
                email: data.email
            }

            await axios.post(API, postData,
                    {
                        headers:{
                            "Content-Type": "application/json"
                        }
                    }
                )

            dispatch({
                type: GOOGLE_LOGIN_SUCCESS,
                payload: data,
            });
        }

    }catch(error){
        dispatch({
            type: GOOGLE_LOGIN_FAIL,
            payload: error.response.data.message,
        }) 
    }
}


//Load user
export const loadUser = () => async(dispatch, getState) =>{
    try{
        dispatch({type: LOAD_USER_REQUEST});

        const token = localStorage.getItem('token')
        const userData = JSON.parse(localStorage.getItem('userData'))
        
        if(token){
            return dispatch({
                type: LOAD_USER_SUCCESS,
                payload: userData
            })
        }

        const getLoginMethod = localStorage.getItem('loginMethod')
        if(getLoginMethod ==='google'){

            const {data} = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
                headers:{
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json"
                }
            })

            // localStorage.setItem('userData', JSON.stringify(data))
            sessionStorage.setItem('userData', JSON.stringify(data))

            dispatch({
                type: LOAD_USER_SUCCESS,
                payload: data
            })

        }
        
    }catch(error){
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const logoutUser = () => async(dispatch) => {
    try{

        localStorage.removeItem('token')
        localStorage.removeItem('id')
        localStorage.removeItem('loginMethod')
        localStorage.removeItem('userData')
        sessionStorage.removeItem('userData')
        sessionStorage.removeItem('firstLogin')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('token')

        dispatch({type: LOGOUT_USER_SUCCESS})
    }catch(error){
        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}