import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MetaData from '../MetaData';
import CartItem from './CartItem';
import EmptyCart from './EmptyCart';
import PriceSidebar from './PriceSidebar';
import { getCartItems } from '../../actions/cartAction';
import { clearErrors, getUserDashboardData } from '../../actions/dashboardAction';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import { resetPromoCode } from '../../actions/promoAction';
import { getAllPromoCodes } from '../../actions/orderAction';
// import { formatCurrency } from '../../utils/functions';
// import DialogBox from '../Product/DialogBox';

const Cart = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const { cartData, error: cartDataError, loading: cartLoading } = useSelector(state => state.cart)
    const { isAuthenticated, user } = useSelector(state => state.user)
    const { loading: dashboardLoading,   } = useSelector(state => state.dashboard)
    const { promoCode, promoCodeValid } = useSelector(state => state.promo);
    const { getAllPromoCode, } = useSelector(state => state.orders)
    // const [isDialogOpen, setDialogOpen] = useState(false); 

    useEffect(() => {
        if(cartDataError){
            enqueueSnackbar(cartDataError, {variant:"error"})
            dispatch(clearErrors())
        }
        if (isAuthenticated && user?.id) { // Check if user exists and has an id
            const userId = user.id;
            dispatch(getCartItems(userId));
        }
        dispatch(getUserDashboardData())
        dispatch(getAllPromoCodes())

        window.scrollTo(0,0)
    }, [dispatch,enqueueSnackbar,cartDataError, isAuthenticated, user?.id])
    

    const validPromoCode = getAllPromoCode && getAllPromoCode.length > 0 && promoCode
        ? getAllPromoCode.find(promo => promo.status === "valid" && promo.promo_code === promoCode)
        : null;

    useEffect(() => {
        const totalCartPrice = cartData && cartData.length > 0 && cartData?.reduce((total, item) => total + (item.product_new_price * item.qty), 0).toFixed(2);
        if (validPromoCode && totalCartPrice < validPromoCode.minimum_order_value && promoCodeValid) {
            dispatch(resetPromoCode());
            dispatch(clearErrors())
        }
    }, [cartData, dispatch, promoCodeValid, validPromoCode,])

    
    if(!isAuthenticated ){
        return navigate('/login')
    }

    //const minimumOrderAmount = 4;

    // const countPrice = cartData && cartData.length > 0 && cartData.reduce((sum, item) => sum + (parseFloat(item.product_new_price) * parseInt(item.qty)), 0).toFixed(2);

    // const isOrderEligible = ()  => {
    //     return countPrice > minimumOrderAmount;
    // }
    
    const placeOrderHandler = () => {
        if(!isAuthenticated){
            return navigate('/login')
        }
        // if(!isOrderEligible()){
        //     setDialogOpen(true)
        //     return
        // }
        navigate("/shipping")
    }

    // const handleCloseDialog = () => {
    //     setDialogOpen(false)
    // }
    // const handleDialogAction = () => {
    //     navigate("/")
    // }

  return (

    <div>
      <MetaData title="Shopping Cart" />
        <main  className='w-full mt-2 mb-8 px-2'>
            {dashboardLoading ? <Loader /> : (
                <div>
                    {cartData && cartData.length > 0 ? (
                        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">

                            {/* <!-- cart column --> */}
                            <div className="flex-1">
                                {/* <!-- cart items container --> */}
                                <div className="flex-col shadow bg-white">
                                    <h1 className="px-6 py-3 border-b font-medium text-gray-500">My Cart ({cartData.length})</h1>
                                    {cartData && cartData.length > 0 && cartData.map((item) => (
                                            <div key={item.product_id}>
                                                <CartItem {...item} inCart={true} />
                                            </div>
                                                
                                        )
                                    )}
            
                                    {/* <!-- place order btn --> */}
                                    {cartData && cartData.length > 0 && (
                                        <div className='flex justify-end'>
                                            <button 
                                                name='place-order'
                                                onClick={placeOrderHandler} 
                                                className={`${cartData.length < 1 ? "bg-primary-grey cursor-not-allowed" : "bg-green-500"} w-full sm:w-1/3 mx-2 sm:mx-6 my-4 py-3 font-medium text-white shadow hover:shadow-lg rounded-md`}
                                                disabled={cartData.length < 1 ? true : false}
                                            >
                                                <span className='font-bold'>Place Order</span>
                                            </button>
                                        </div>
                                    )}
                                    {/* <!-- place order btn --> */}
            
                                </div>
                                {/* <!-- cart items container --> */}
                                
                            </div>
                            {/* <!-- cart column --> */}
            
                            {cartData && cartData.length > 0 && <PriceSidebar cartItems={cartData} />}
        
                        </div>

                    ) : (
                        !cartLoading && 
                        <EmptyCart />
                    )}
                </div>
            )}
        </main>

        {/* <DialogBox
            open={isDialogOpen}
            handleClose={handleCloseDialog}
            handleAction={handleDialogAction}
            dialogTitle="Attention"
            dialogMessage={`The minimum order amount should be more than ${formatCurrency(minimumOrderAmount)}`}
            cancelButtonName='Upgrade quantity'
            buttonName="Add more item"
        /> */}
    </div>
  )
}

export default Cart;
