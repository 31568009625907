import React from 'react'
import { Link } from 'react-router-dom'

const EmptyCart = () => {
  return (
    <div className="mt-0 h-72 md:h-80 lg:h-72 flex items-center justify-center flex-col gap-2 py-0 px-1  md:px-5">
      <div className="max-w-lg text-center">
        <h1 className="text-3xl font-semibold mb-3">Your cart is empty!</h1>
        <p className="text-gray-600 mb-3">Add items to it now</p>
      </div>
        <Link to="/" className="bg-black text-white font-semibold py-3 px-8 rounded-md shadow hover:bg-gray-900 transition duration-300">
          Shop Now
        </Link>
    </div>
  )
}

export default EmptyCart;
