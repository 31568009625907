import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { clearErrors, } from '../../actions/userAction';
import MetaData from '../MetaData';
import { useSnackbar } from 'notistack';
import GoogleSignIn from '../Google/GoogleSignIn';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();;
    const { enqueueSnackbar } = useSnackbar()

    const { isAuthenticated,  error} = useSelector(state => state.user)


    const redirect = location.search ? location.search.split("=")[1] : "account";

    useEffect(() => {
        if(error){
            if (error) {
                enqueueSnackbar(error, { variant: "error" });
                dispatch(clearErrors());
            }
            if (isAuthenticated) {
                // navigate(`/`)
                navigate(`/${redirect}`)
            }
        }
        window.scrollTo(0,0)
    }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar])

  return (

    <div id="" tabIndex="-1" className="top-0 right-0 left-0 z-50 items-center justify-center flex">
        <MetaData title="Login" />
        <div className="relative p-4 max-w-md h-full md:h-auto">

            <div className="relative bg-white rounded-lg shadow">

                <div className="p-5">
                    <div className="text-center">
                        <h1 className="mb-1 text-xl md:text-xl lg:text-2xl font-semibold leading-normal md:leading-relaxed lg:leading-loose text-black">
                            Login to your account
                        </h1>
                        <h2 className="mt-1 text-sm md:text-base lg:text-sm leading-normal  text-gray-600">
                            You must be logged in to perform any operation.
                        </h2>
                    </div>

                    <div className="mt-7 flex flex-col gap-2">
                        <GoogleSignIn />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

  )
}

export default Login;
