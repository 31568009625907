import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import successfull from '../../assets/images/Transaction/success.png';
import failed from '../../assets/images/Transaction/failed.png';
import MetaData from '../MetaData';

const OrderSuccess = ({success}) => {
    const navigate = useNavigate();
    const [time, setTime] = useState(3)

    useEffect(() => {
        if(time === 0){
            if(success){
                navigate("/orders")
            }else{
                navigate("/cart")
            }
            return;
        }
        const intervalId = setInterval(() => {
            setTime(time - 1)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [time, navigate, success])
  return (
    <div>
        <MetaData title={`Transaction ${success ? "Successfull" : "Failed"}`} />

        <main className='w-full mt-18'> 

            {/* row */}
            <div className="flex flex-col gap-2 items-center justify-center sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow rounded p-6 pb-12">
                <img draggable="false" className="w-1/2 h-60 object-contain" src={success ? successfull : failed} alt="Transaction Status" />
                <h1 className="text-2xl font-semibold">Transaction {success ? "Successfull" : "Failed"}</h1>
                <p>Redirecting to {success ? "orders" : "cart"} in {time} sec</p>
                <Link to={success ? "/orders" : "/cart"} className='bg-primary-blue mt-2 py-2.5 px-6 text-white uppercase shadow hover:shadow-lg rounded-sm'>go  to {success ? "orders" : "cart"}</Link>
            </div>
             {/* row */}
        </main>
    </div>
  )
}

export default OrderSuccess;
