import './App.css';
import { Routes, Route, } from 'react-router-dom';
import Login from './components/User/Login';
import { useEffect, lazy } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { loadUser } from './actions/userAction';
import { useDispatch, } from 'react-redux';
import AllProduct from './components/Product/AllProduct';
import ProductList from './components/Product/ProductList';
import SingleProduct from './components/Product/SingleProduct';
import Cart from './components/Cart/Cart'
import Shipping from './components/Cart/Shipping';
import OrderConfirm from './components/Cart/OrderConfirm';
import Payment from './components/Cart/Payment';
import OrderSuccess from './components/Cart/OrderSuccess';
import Wishlist from './components/Wishlist/Wishlist';
import NotFound from './components/NotFound';
import ProtectedRoute from "../src/routes/ProtectedRoute"
import Account from './components/User/Account';
import MyOrders from './components/Order/MyOrders';
import OrderDetails from './components/Order/OrderDetails';
import AboutUs from './components/AboutUs/AboutUs';
import SearchResult from './components/Product/SearchResult';
import ProductsByCategory from './components/Product/ProductsByCategory';
import CategoryProduct from './components/Product/CategoryProduct';

const Home = lazy(() => import('./Pages/Home/Home'))

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token')
    
    if(token){
      dispatch(loadUser())
    }
  }, [dispatch]);
  return (
    <div>
      <Header/>
      <ProductList />
        <Routes>
          <Route exact path="/" element={ <Home /> } />
          <Route exact path='/login' element={<Login/>} />
          <Route exact path='/products' element={ <AllProduct /> }/>
          <Route exact path='/search' element={ <SearchResult />}/>
          <Route exact path= "/products/:productName/:pid/buy" element={ <SingleProduct /> } />
          <Route exact path='/category/:categoryName?' element={ <ProductsByCategory/> }/>
          <Route exact path="/category/:categoryName/products/:productName/:pid/buy" element={ <CategoryProduct/> } />
          <Route exact path='/about-us'  element={<AboutUs />} />
          
          <Route exact path="/cart" element={ 
            <ProtectedRoute>
              <Cart /> 
            </ProtectedRoute>
            }
          />

          {/* Order process */}
          <Route exact path='/shipping' element={
              <ProtectedRoute>
                <Shipping />
              </ProtectedRoute>
          } />

          <Route exact path="/order/confirm" element={
            <ProtectedRoute>
              <OrderConfirm />
            </ProtectedRoute>
          } />

          <Route exact
            path='/process/payment'
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            } 
          />

          <Route exact path='/orders/success' element={ <OrderSuccess success={true} /> } />
          <Route exact path="/orders/failed" element={ <OrderSuccess success={false} />} />

          <Route
            path='/orders'
            element ={
              <ProtectedRoute>
                <MyOrders />
              </ProtectedRoute>
            }
          />

          <Route 
            path="/order-details/:id"
            element = {
              <ProtectedRoute>
                <OrderDetails/>
              </ProtectedRoute>
            }
          />

          <Route exact
            path='/wishlist'
            element={
              <ProtectedRoute>
                <Wishlist/>
              </ProtectedRoute>
            }
          />

          <Route exact path='/my-profile'
            element={
              <ProtectedRoute>
                <Account/>
              </ProtectedRoute>
            }
          />


          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer/>
    </div>
  )
}

export default App;
