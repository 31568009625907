import { ADD_TO_CART, ADD_TO_CART_FAILED, ADD_TO_CART_REQUEST, CART_DETAIL_FAILED, CART_DETAIL_REQUEST, CART_DETAIL_SUCCESS, EMPTY_CART, FAILED_TO_REMOVE_FROM_CART, REMOVE_FROM_CART, UPDATE_QUANTITY_FAILED, UPDATE_QUANTITY_REQUEST, UPDATE_QUANTITY_SUCCESS } from "../constants/cartConstants";


const initialState = {
    cartData: JSON.parse(localStorage.getItem('cartData')) || [],
    loading: false,
    error: null
}

export const cartReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case ADD_TO_CART_REQUEST:
        case CART_DETAIL_REQUEST:
        case UPDATE_QUANTITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_TO_CART:
            return {
                ...state,
                loading: false,
                cartData: [...state.cartData, {product_id: payload.product_id, qty: payload.quantity}],
                error: null
            }
        case CART_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                cartData: payload,
                error: null
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                loading: false,
                cartData: state.cartData.filter(item => item.product_id !== payload),
                error: null
            }
        case UPDATE_QUANTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                cartData: state.cartData.map(item => 
                    item.product_id === payload.product_id
                    ? {...item, qty: payload.quantity}
                    : item
                ),
                error: null,
            }
        case EMPTY_CART:
            return {
                ...state,
                cartData: []
            }
        case ADD_TO_CART_FAILED:
        case CART_DETAIL_FAILED:
        case UPDATE_QUANTITY_FAILED:
        case FAILED_TO_REMOVE_FROM_CART:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}