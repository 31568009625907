import { Step, StepLabel, Stepper} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { formatDate } from "../../utils/functions";

const TrackStepper = ({ activeStep, orderOn, shippedAt, deliveredAt, orderStatus }) => {

    const steps = [
        {
            status: "Pending",
            dt: formatDate(orderOn)
        },
        {
            status: "Confirmed",
            dt: formatDate(shippedAt)
        },
        {
            status: "Ready to Ship",
            dt: formatDate(deliveredAt)
        },
        {
            status: "in Shipment",
            dt: formatDate(deliveredAt)
        },
        {
            status: "Out for Delivery",
            dt: formatDate(deliveredAt)
        },
        {
            status: "Delivered",
            dt: formatDate(deliveredAt)
        },
        {
            status: "Returned",
            dt: formatDate(deliveredAt)
        },
        {
            status: "Canceled",
            dt: formatDate(deliveredAt)
        },
    ];

    const completedIcon = <span className="text-primary-green animate-pulse"><CircleIcon sx={{fontSize: "16px"}} /></span>
    const pendingIcon = <span className="text-gray-400"><CircleIcon sx={{ fontSize: "16px" }} /></span>;

  return (

    <Stepper activeStep={activeStep} alternativeLabel>
    {steps.map((item, index)  => {
        if ((item.status === 'Returned' || item.status === 'Canceled') && orderStatus !== item.status) {
            return null; // Skip rendering for "Returned" and "Canceled" steps when orderStatus is not "Returned" or "Canceled"
        }
        return (
            <Step key={index} 
                active={activeStep === index ? true : false}
                completed={activeStep >= index ? true : false}
            >
                <StepLabel icon={activeStep === index ? completedIcon: pendingIcon} >
                    {activeStep >= index ? (
                        <div className="flex flex-col">
                            <span className="text-green-600 text-md text-semibold">{item.status}</span>
                            {/* {item.dt !== "Invalid Date" && (
                                <span className="text-primary-green font-medium">{item.dt}</span>
                            )} */}
                        </div>
                    ) : (
                        <span className="text-gray-400 font-medium">{item.status}</span>
                    )}
                </StepLabel>
            </Step>
        );
    })}
    </Stepper>
  )
}

export default TrackStepper;
