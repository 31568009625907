import React from 'react';
import { Link } from 'react-router-dom';
import { AccountCircleOutlined, FavoriteOutlined, HomeOutlined, InfoOutlined, InventoryOutlined, ShoppingCartOutlined } from '@mui/icons-material';

const Footer = () => {
    return (

        <>
            <footer className="font-sans tracking-wide bg-green-500 pt-8 h-55 px-4 sm:px-6 lg:px-10">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                    {/* Brand Section */}
                    <div className="flex-1 mb-6 sm:mb-0">
                        <Link to="/">
                            <h2 className="text-white text-2xl font-bold mb-4 hover:underline">Trustyindian.com</h2>
                        </Link>
                        <p className="text-white text-sm text-justify">
                            Trustyindian.com, your one stop online Indian store to buy Indian products. You'll find a wide range of traditional Indian snacks and sweets, namkeen like wafers, bhujiya, khakhra and many more things at our store. Currently operating only in Riga, Latvia.
                        </p>
                    </div>
                    
                    {/* Links Section */}
                    <div className="flex-1 flex flex-col sm:flex-row sm:justify-around mb-6 sm:mb-0">
                        {/* First Column of Links */}
                        <ul className="flex flex-col">
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <HomeOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">Home</strong>
                                </Link>
                            </li>
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/about-us" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <InfoOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">About Us</strong>
                                </Link>
                            </li>
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/my-profile" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <AccountCircleOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">My Profile</strong>
                                </Link>
                            </li>
                        </ul>

                        {/* Second Column of Links */}
                        <ul className="flex flex-col">
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/products" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <InventoryOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">Products</strong>
                                </Link>
                            </li>
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/cart" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <ShoppingCartOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">Cart</strong>
                                </Link>
                            </li>
                            <li className="flex items-center text-white hover:underline mb-4">
                                <Link to="/wishlist" className="flex items-center gap-2">
                                    <div className="bg-gray-200 text-blue-500 h-10 w-10 rounded-full flex items-center justify-center">
                                        <FavoriteOutlined className='hover:text-blue-700 cursor-pointer' />
                                    </div>
                                    <strong className="hover:text-blue-700">Wishlist</strong>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className="bg-black text-white py-2">
                <div className="flex justify-center">
                    <p className="text-sm">&copy;{new Date().getFullYear()} Trustyindian.com. All rights reserved.</p>
                </div>
            </div>
        </>
      
    )
      
}

export default Footer
