import React, { useEffect } from 'react'
import { getCartItems } from '../../actions/cartAction';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTaxAmount, calculateTotalAmount, formatCurrency } from '../../utils/functions';
import PromoCode from './PromoCode';

const PriceSidebar  = ({ cartItems,}) => {

  const dispatch = useDispatch();
 
  const { isAuthenticated, user } = useSelector(state => state.user)
  const { taxPercentage, deliveryCharges } = useSelector(state => state.orders.priceBar)
  const { discount } = useSelector(state => state.promo)

  useEffect(() => {
    if(isAuthenticated && user?.id){
      const userId = user.id;
      dispatch(getCartItems(userId))
    }
  }, [dispatch, isAuthenticated, user?.id])


  const isFreeShipping = deliveryCharges === 0;
  const displayShippingCharge = isFreeShipping ? formatCurrency(2.5) : formatCurrency(deliveryCharges);
  
  // Calculate total price of items in the cart
  const totalPrice = cartItems && cartItems.length > 0 && cartItems.reduce((sum, item) => sum + (parseFloat(item.product_new_price) * parseInt(item.qty)), 0);

  //discounted price
  const discountedPrice = totalPrice - (totalPrice * (discount / 100))
  
  const discountAmount = totalPrice * discount / 100;
  
  // Calculate tax amount based on the discounted price
  const taxAmount = calculateTaxAmount(discountedPrice, taxPercentage);

  // Calculate total amount including delivery charges and tax
  const totalAmount = calculateTotalAmount(discountedPrice, taxPercentage).toFixed(2);

  return (
    <div className='flex sticky top-12 flex-col sm:w-4/12 sm:px-1'>
      {/* <!-- nav tiles --> */}
      <div className="flex flex-col bg-white rounded-lg shadow">
        <h1 className="px-6 py-3 border-b font-medium text-gray-500">PRICE DETAILS</h1>

        <div className="flex flex-col gap-4 p-6 pb-3">
          <p className="flex justify-between">Price ({cartItems.length} item) <span>{formatCurrency(totalPrice)}</span></p>
          {discount > 0 && (
            <>
              <p className="flex justify-between">Discount ({discount}%) <span className="text-primary-green">{formatCurrency(discountAmount)}</span></p>
              <div className="border border-dashed"></div>
              <p className="flex justify-between">Sub Total<span>{formatCurrency(discountedPrice)}</span></p>
            </>
          )}
          <p className="flex justify-between">Shipping Charges <span className={`text-primary-green ${isFreeShipping ? 'line-through decoration-2 decoration-red-500' : ''}`}>{displayShippingCharge}</span></p> 
          <p className="flex justify-between">Tax ({taxPercentage}%) <span className="text-primary-green">{formatCurrency(taxAmount)}</span></p>
          <div className="border border-dashed"></div>
          <p className="flex justify-between text-lg font-medium">Total Amount <span>{ formatCurrency(totalAmount)}</span></p>
          <div className="border border-dashed"></div>

          <PromoCode cartItems={cartItems}/>
          {/* <PromoCode setDiscount={setDiscount} promoCodeValid={promoCodeValid} setPromoCodeValid={setPromoCodeValid} cartItems={cartItems} /> */}
        </div>
      </div>
      {/* <!-- nav tiles --> */}
    </div>
  )
}

export default PriceSidebar;
