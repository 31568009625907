import { CANCEL_ORDER_FAILED, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, GET_ALL_PROMOCODE_FAILED, GET_ALL_PROMOCODE_REQUEST, GET_ALL_PROMOCODE_SUCCESS, MY_ORDERS_FAIL, MY_ORDERS_REQUEST, MY_ORDERS_SUCCESS, NEW_ORDER_FAIL, NEW_ORDER_REQUEST, NEW_ORDER_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, } from "../constants/orderConstants";
import { CLEAR_ERRORS } from "../constants/productConstants";

const initialState = {
    newOrder: {},
    myOrders: [],
    orderDetails: {},
    cancelOrderId:'',
    promoCodeData: {},
    getAllPromoCode: {},
    loading: false,
    error: null
}
export const orderReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case NEW_ORDER_REQUEST:
        case MY_ORDERS_REQUEST:
        case ORDER_DETAILS_REQUEST:
        case CANCEL_ORDER_REQUEST:
        case GET_ALL_PROMOCODE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case NEW_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                newOrder: payload,
                myorders: [...state.myOrders,  {order_id: payload.orderId}],
                error: null
            }
        case MY_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                myOrders: payload,
                error: null
            }
        case CANCEL_ORDER_SUCCESS: 
            return {
                ...state,
                loading: false,
                cancelOrderId: payload.orderId,
                myOrders: state.myOrders.filter((order) => order.order_id !== payload.orderId),
                error: null
            }
        case ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                orderDetails: payload,
                error: null
            }
        case GET_ALL_PROMOCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllPromoCode: payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        case NEW_ORDER_FAIL:
        case MY_ORDERS_FAIL:
        case ORDER_DETAILS_FAIL:
        case CANCEL_ORDER_FAILED:
        case GET_ALL_PROMOCODE_FAILED:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state
    }
}