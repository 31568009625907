import React, { useCallback, useEffect, useRef, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { HomeOutlined, LocationOn } from '@mui/icons-material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { loadUser, logoutUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import { clearErrors } from '../../actions/dashboardAction';
import { getCartItems } from '../../actions/cartAction';
import { myOrders } from '../../actions/orderAction';
import SearchBar from './SearchBar';
import trustyIcon from "../../assets/images/friend.png"

const Header = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const submenuRef = useRef(null)
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartData, error: cartDataError } = useSelector(state => state.cart);
  const { wishlistItems } = useSelector((state) => state.wishlist);


  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    if(cartDataError){
      enqueueSnackbar(cartDataError, {variant: "error"})
      dispatch(clearErrors())
    }
    if(isAuthenticated && user?.id){  //Check if user exists and has an id
      const userId = user.id;
      dispatch(getCartItems(userId))
      dispatch(myOrders(userId))
    }
  }, [dispatch, enqueueSnackbar, cartDataError, isAuthenticated, user?.id])

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
    enqueueSnackbar("Logout Successfully", { variant: "success" });
  }


  const subMenu = [
    {
      link: "/",
      icon: <HomeOutlined className="h-6 w-6" />,
      name: "Home"
    },
    {
      link: "/my-profile",
      icon: <AccountCircleIcon className="h-6 w-6" />,
      name: "My Profile"
    },
    {
      link: "/orders",
      icon: <ShoppingBagIcon className='h-6 w-6' />,
      name: "Orders",
    },
    {
      link: '/wishlist',
      icon: <FavoriteBorderIcon className='h-6 w-6' />,
      name: "Wishlist", 
      totalItem: wishlistItems && wishlistItems.length
    },

  ]

  //Function to close submenu
  const closeSubMenu = useCallback(() => {
    setShowSubMenu(false);
  }, []);
  
  useEffect(() => {
    //close submenu when clicking outside
    const handleClickOutside = (event) => {
      if(submenuRef.current && !submenuRef.current.contains(event.target)){
        closeSubMenu()
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside)
    };
  }, [submenuRef, closeSubMenu])
  // Function to handle menu item click
  const handleMenuItemClick = () => {
    closeSubMenu();
  };

  useEffect(() => {
    dispatch(loadUser())
  }, [dispatch])

  useEffect(() => {
    if(cartDataError){
      enqueueSnackbar(cartDataError, {variant: "error"})
      dispatch(clearErrors())
    }
    if(isAuthenticated && user?.id){  //Check if user exists and has an id
      const userId = user.id;
      dispatch(getCartItems(userId))
      dispatch(myOrders(userId))
    }
  }, [dispatch, enqueueSnackbar, cartDataError, isAuthenticated, user?.id])

  return(

    <header className='bg-green-500 border-b font-sans text-lg min-h-[65px] px-4 py-2 top-0 sticky z-50 shadow-md'>
      <div className='flex flex-wrap items-center  max-sm:gap-x-2'>
        <Link to='/' className="flex items-center space-x-3">
          <div className="flex items-center space-x-1 text-2xl font-bold">
          <img src={trustyIcon} alt='Trusty' className="h-12 bg-transparent rounded-full shadow-lg" title="trustyindian" loading='lazy' />
            <h1 className="text-white font-display pl-1 drop-shadow-lg">Indian</h1>
          </div>
        </Link>
        
        {/* SearchBar */}
        <SearchBar/>

        <div className='flex items-center ml-auto space-x-10 text-xl'>
          <div className='max-sm:hidden'>
            <div className='max-sm:flex items-center bg-white rounded-full px-3 py-1'>
              <LocationOn className="mb-1 mr-1 text-black text-sm" />
              <span className="text-black items-center text-sm font-semibold">Latvia</span>
            </div>
          </div>
        {isAuthenticated ? (
          <div className='group relative userDropDown flex items-center text-white font-medium gap-1 cursor-pointer' ref={submenuRef} >
            <button 
              type="button"
              name='user-menu' 
              className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" 
              id="user-menu-button" 
              aria-expanded="false" 
              data-dropdown-toggle="user-dropdown" 
              data-dropdown-placement="bottom"
              onClick={() => setShowSubMenu(!showSubMenu)}
            >
            <img className="w-8 h-8 rounded-full" src={user && user.picture ? user.picture : user.given_name.charAt(0)} alt={user.given_name.charAt(0)} title={user.given_name} loading='lazy' />
            </button>
            {showSubMenu && (
              <div className='absolute top-full -right-4 mt-2 w-60 bg-white shadow-2xl rounded text-sm'>
                <div className="px-4 py-3 border-b">
                  <span className="block text-sm text-gray-900 dark:text-white">{user.name}</span>
                  <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">{user.email}</span>
                </div>
                {subMenu && subMenu.length > 0 && subMenu.map((menu) => (
                  <Link to={menu.link} className="text-black pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-200 rounded-t" key={menu.name} onClick={handleMenuItemClick} >
                    <span className="text-primary-blue">
                      {menu.icon}
                    </span>
                    <span className="">{menu.name}</span>

                    {menu && menu.name === 'Wishlist' && (
                      <span className="ml-auto mr-3 bg-gray-100 p-0.5 px-2 text-gray-600 rounded">
                        {menu.totalItem}
                      </span>
                    )}
                  </Link>
                ))}

                <div className='text-black pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-200 rounded-t'  onClick={handleLogout}>
                  <span className="text-primary-blue"><PowerSettingsNewIcon sx={{ fontSize: "18px" }} /></span>
                  Logout
                </div>

              </div>
            )}
          </div>
        ): (
          <Link to="/login" className="relative flex items-center text-gray-50 font-medium gap-1 cursor-pointer hover:text-blue-500">
            <span className="hover:text-white">Login</span>
          </Link>
        )}
  
        {isAuthenticated ? (
          <div className='flex'>
            <Link to="/cart">
              <span className='relative text-gray-50 text-lg'>
                <ShoppingCartIcon  className='hover:text-white' />
                {cartData && cartData.length > 0 &&
                  <span className='absolute left-auto -ml-2 -top-1 rounded-full bg-red-500 px-1 py-0 text-xs text-white'> 
                    {cartData && cartData.length}
                  </span>
                }
              </span>
            </Link>
          </div>  
        ): (
          <div className='flex'>
            <Link to="/cart">
              <span className='relative text-gray-50'>
                <ShoppingCartIcon className='hover:text-white' />
              </span>
            </Link>
          </div>  
        )}
      </div>
    </div>

      <div className="max-sm:absolute top-0 right-0 mt-0 mr-3 ">
        <div className="max-sm:block hidden mt-3">
          <div className='flex items-center bg-white rounded-full px-2 py-1'>
            <LocationOn className="h-4 w-4 text-black text-sm mr-0" />
            <span className="text-black text-sm font-semibold">Latvia</span>
          </div>
        </div>
      </div>
    </header>
      
  )
}


export default Header
