export const ADD_TO_CART_REQUEST = "GET_CART_ReQUEST";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";


export const CART_DETAIL_REQUEST = "CART_DETAIL_REQUEST";
export const CART_DETAIL_SUCCESS = "CART_DETAIL_SUCCESS";
export const CART_DETAIL_FAILED = "CART_DETAIL_FAILED";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";


export const EMPTY_CART = "EMPTY_CART";

export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";
export const FAILED_TO_REMOVE_FROM_CART = "FAILED_TO_REMOVE_FROM_CART";


export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";


export const UPDATE_QUANTITY_REQUEST = "UPDATE_QUANTITY_REQUEST";
export const UPDATE_QUANTITY_SUCCESS = "UPDATE_QUANTITY_SUCCESS";
export const UPDATE_QUANTITY_FAILED = "UPDATE_QUANTITY_FAILED";

export const SAVE_SHIPPING_ADDRESS_SUCCESS = "SAVE_SHIPPING_ADDRESS_SUCCESS";
export const FAILED_TO_SAVE_SHIPPING_ADDRESS = "FAILED_TO_SAVE_SHIPPING_ADDRESS";

export const SUCCESS_TO_GET_SHIPING_ADDRESS = "GET_SHIPING_ADDRESS";
export const FAILED_TO_GET_SHIPING_ADDRESS = "FAILED_TO_GET_SHIPING_ADDRESS";