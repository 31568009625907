import React, { useEffect, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import ProductList from './ProductList';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import { clearErrors, getUserDashboardData } from '../../actions/dashboardAction';

const ProductList = lazy(() => import("./ProductList"))

const AllProduct = () => {

    const dispatch  = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {dashboardData, loading: dashboardLoading, error: dashboardError } = useSelector(state => state.dashboard)

    useEffect(() => {
      if(dashboardError){
        enqueueSnackbar(dashboardError, {variant:"error"});
        dispatch(clearErrors())
      }
      dispatch(getUserDashboardData())
      window.scrollTo(0,0)
    }, [dispatch, dashboardError, enqueueSnackbar])

  return (
    <div>
      {dashboardLoading ? <Loader /> : (
          <div>
           <h1 className="text-3xl font-semibold mb-3 pt-0 px-4">
              <span className="text-blue-500">All Products</span>
            </h1>
           {dashboardData && dashboardData.inventory && dashboardData.inventory.length > 0 && <ProductList products={dashboardData.inventory} />} 
          </div>
        ) 
      }
    </div>
  )
}

export default AllProduct;
