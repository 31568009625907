import React, { useEffect } from 'react'
import Product from './Product'
import MetaData from '../MetaData'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getProductList } from '../../actions/productAction';
import { getWishlistItems } from '../../actions/wishlistAction';
import Loader from '../Loader';

const Wishlist = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { wishlistItems, loading: wishlistLoading} = useSelector(state => state.wishlist)
  const { isAuthenticated, user } = useSelector(state => state.user)

  useEffect(() => {
    if(!isAuthenticated){
        return navigate('/login')
    }
    dispatch(getProductList())
    // dispatch(getUserDashboardData())
    if (isAuthenticated && user?.id) { // Check if user exists and has an id
        const userId = user.id;
        dispatch(getWishlistItems(userId))
    }

    window.scrollTo(0,0)

  }, [dispatch, navigate, isAuthenticated, user?.id])

  return (
    <div>
        <MetaData title="Wishlist" />

        {/* <MinCategory /> */}

        <main className='w-full mt-8 sm:mt-0'>
            {wishlistLoading ? <Loader /> :(
                <div>
                    {wishlistItems && wishlistItems.length > 0 ? (
                        <div className='flex gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7 px-2'>
                            <div className='flex-1 shadow bg-white mb-12'>
                                {/* Wishlist container */}
                                <div className='flex flex-col'>
                                    <span className='font-medium text-lg px-4 sm:px-1 py-4 border-b'>My Wishlist ({wishlistItems && wishlistItems.length})</span>
                                    { wishlistItems && wishlistItems.length > 0 && wishlistItems.map((item) => (
                                        <Product {...item} key={item.product_id} />
                                    )).reverse()}
                                </div>
                                {/* Wishlist container */}
                            </div>

                            </div> 
                    ): (
                        !wishlistLoading && (

                            <div className="mt-0 h-72 md:h-80 lg:h-72 flex items-center justify-center flex-col gap-2 py-0 px-1 md:px-5">
                                <div className="max-w-lg text-center">
                                    <h2 className="text-3xl font-semibold mb-3">Your wishlist is empty!</h2>
                                    <p className="text-gray-600 mb-3">Add your favourite items to it now</p>
                                </div>
                                <button onClick={() => navigate(-1)} name="go-back" className="bg-black text-white font-semibold py-3 px-8 rounded-md shadow hover:bg-gray-900 transition duration-300">
                                    Go Back
                                </button>
                            </div> 
                        )

                
                    )}
                </div>
            )}
        </main>
    </div>
  )
}

export default Wishlist;
