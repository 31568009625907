import axios from "axios";
import { CHECK_PROMOCODE_REQUEST, CLEAR_ERRORS, PROMOCODE_ERROR, PROMOCODE_INVALID, PROMOCODE_VALID, RESET_PROMO_CODE, } from "../constants/promocodeConstant"

const API = process.env.REACT_APP_API_URL;


export const validatePromoCode = (orderValue, userId, promoCode) => async(dispatch) => {
    try{
        dispatch({type: CHECK_PROMOCODE_REQUEST})

        const reqData = {
            operation: "codeValidation",
            order_value: orderValue,
            uid: userId,
            promo_code: promoCode,
        }

        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })

        if(data.status === "valid"){
            dispatch({
                type: PROMOCODE_VALID,
                payload: {
                    promoCode: reqData.promo_code,
                    discount: data.discount,
                    message: data.message
                }
            })

            localStorage.setItem('promoCode', JSON.stringify({
                promoCode: reqData.promo_code,
                discount: data.discount,
                promoCodeValid: true
            }));


        } else {
            dispatch({
                type: PROMOCODE_INVALID,
                payload: {
                    message: data.message,
                },
            })

        }

    }catch(error){
        console.log('Error during validating promo code: ',error)
        dispatch({
            type: PROMOCODE_ERROR,
            payload: error.response?.data.messages || error.messages
        })
    }
}


export const resetPromoCode = () => async(dispatch) => {
    dispatch({
        type: RESET_PROMO_CODE
    })
    
    localStorage.removeItem('promoCode')
}

export const clearErrors = () =>  (dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}