
import React from 'react'
import { Delete } from '@mui/icons-material';
import { ShoppingCart } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { getWishlistItems, removeItemFromWishlist } from '../../actions/wishlistAction';
import { addItemsToCart, getCartItems } from '../../actions/cartAction';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';
import { formatCurrency, slugify } from '../../utils/functions';

const Product = (props) => {

    const { product_id, product_img1, product_name, product_new_price, stock } = props;
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {isAuthenticated, user} = useSelector(state => state.user)


    if(!isAuthenticated){
       return navigate("/login")
    }
    const handleAddToCart = async() => {
        await dispatch(addItemsToCart(user.id, product_id))
        await dispatch(getCartItems(user.id))
        enqueueSnackbar('Product added to cart', {variant:"success"})
    }
    const handleRemoveProuct = async() => {
        await dispatch(removeItemFromWishlist(user.id, product_id))
        await dispatch(getWishlistItems(user.id))
        enqueueSnackbar('Product removed from wishlist', {variant:"success"})
    }


    
  return (

    <div className='flex gap-4 border-b p-4 sm:pb-8 w-full group overflow-hidden'>
        <div className='w-1/6 h-28 flex-shrink-0'>
            <img draggable="false" className='h-full w-full object-contain' src={product_img1} alt={product_name} title={product_name} loading='lazy' />
        </div>

        <div className='flex flex-col justify-between w-full p-1'>
            <Link to={`/products/${slugify(!product_name ? 'good-products' : product_name)}/${product_id}/buy`} >
                <div className='flex flex-col gap-1'>
                    <div className='font-semibold hover:text-blue-700 '>{product_name}</div>
                    <div className='flex items-center gap-2 text-sm text-gray-500 font-medium'>
                        <span>{formatCurrency(product_new_price)}</span>
                    </div>
                </div>
            </Link>

            <div className='flex items-center gap-3'>
                {stock && stock > 0 && (
                    <button name='add-to-cart' onClick={handleAddToCart} className="text-gray-400 hover:text-blue-500 cursor-pointer">
                        <Tooltip title="Add to Cart" arrow>
                            <div>
                                <ShoppingCart />
                            </div>
                        </Tooltip>
                    </button>
                )}
                <button name='remove-item' onClick={handleRemoveProuct} className='text-gray-400 hover:text-red-500'>
                    <Tooltip title="Remove Item" arrow>
                        <div>
                            <Delete />
                        </div>
                    </Tooltip>
                </button>
            </div>
        </div>
    </div>

  )
}

export default Product
