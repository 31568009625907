import { CLEAR_ERRORS, USER_DASHBOARD_FAIL, USER_DASHBOARD_REQUEST, USER_DASHBOARD_SUCCESS } from "../constants/dashboardConstant";

const initialState = {
    loading: false,
    dashboardData: {},
    error: null
};

export const dashboardReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case USER_DASHBOARD_REQUEST:
            return{
                ...state,
                loading: true
            }
        case USER_DASHBOARD_SUCCESS:
            return {
                loading: false,
                dashboardData: payload,
                error: null
            }
        case USER_DASHBOARD_FAIL:
            return {
                loading: false,
                dashboardData: null,
                error: payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}