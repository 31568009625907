import axios from "axios";
import { CANCEL_ORDER_FAILED, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CLEAR_ERRORS, GET_ALL_PROMOCODE_FAILED, GET_ALL_PROMOCODE_REQUEST, GET_ALL_PROMOCODE_SUCCESS, MY_ORDERS_FAIL, MY_ORDERS_REQUEST, MY_ORDERS_SUCCESS, NEW_ORDER_FAIL, NEW_ORDER_REQUEST, NEW_ORDER_SUCCESS, } from "../constants/orderConstants"


const API = process.env.REACT_APP_API_URL;

export const newOrder = (id, payment_mode, address, promoCode,  mobile_no, orderData, totalAmount) => async(dispatch) => {
    try{
        dispatch({type: NEW_ORDER_REQUEST})

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const orderReqBody = {
            operation:"placeOrder",
            uid: id,
            payment_mode: payment_mode,
            delivery_address: address,
            promo_code: promoCode,
            mobile_no: mobile_no,
            product_data: orderData,
            order_amount: totalAmount

        }

        const { data } = await axios.post(API, orderReqBody, config)
        
        dispatch({
            type: NEW_ORDER_SUCCESS,
            payload: {
                message: data.message,
                orderId: data.orderId,
                orderData
            }
        })
    }catch(error){
        dispatch({
            type: NEW_ORDER_FAIL,
            payload: error.response?.data.message || error.message
        })
    }
}

export const myOrders = (id) => async(dispatch) => {
    try{
        dispatch({type: MY_ORDERS_REQUEST})

        const reqBody = {
            operation: "getUserOrderByUserId",
            uid: id
        }
        const { data } = await axios.post(API, reqBody, {
            headers:{
                "Content-Type": "application/json"
            }
        })

        dispatch({
            type: MY_ORDERS_SUCCESS,
            payload: data.orders
        })
    }catch(error){
        dispatch({
            type: MY_ORDERS_FAIL,
            payload: error.response?.data.message || error.message
        })
    }
}

export const cancelOrder = (id, order_id) => async(dispatch) => {
    try{
        dispatch({type: CANCEL_ORDER_REQUEST})

        const reqData = {
            operation: "canceleOrReturnOrder",
            uid: id,
            order_id: order_id,
            order_status: 0 // 0 for cancel order
        }

        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })

        dispatch({
            type: CANCEL_ORDER_SUCCESS,
            payload: {
                message: data.message,
                orderId: order_id
            }
        })
    }catch(error){
        console.log("Error in cancelOrder action: ",error);
        dispatch({
            type: CANCEL_ORDER_FAILED,
            payload: error.response?.data.message || error.message
        })
    }
}

export const clearErrors = () =>  (dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}

export const getAllPromoCodes = () => async(dispatch) => {
    try{
        dispatch({type:GET_ALL_PROMOCODE_REQUEST})

        const reqData = {
            operation: "getAllPromoCode"
        }
        const { data } = await axios.post(API, reqData, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        dispatch({
            type: GET_ALL_PROMOCODE_SUCCESS,
            payload: data
        })

    }catch(error){
        console.log('Error during checking promo code: ',error)
        dispatch({
            type: GET_ALL_PROMOCODE_FAILED,
            payload: error.response?.data.message || error.message
        })
    }
}
