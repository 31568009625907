import { CHECK_PROMOCODE_REQUEST, CLEAR_ERRORS, PROMOCODE_ERROR, PROMOCODE_INVALID, PROMOCODE_VALID, RESET_PROMO_CODE, } from "../constants/promocodeConstant"

const initialState = {
    loading: false,
    promoCode: localStorage.getItem('promoCode') ? JSON.parse(localStorage.getItem('promoCode')).promoCode : '',
    discount: localStorage.getItem('promoCode') ? JSON.parse(localStorage.getItem('promoCode')).discount : 0,
    promoCodeValid: localStorage.getItem('promoCode') ? JSON.parse(localStorage.getItem('promoCode')).promoCodeValid : false,
    error: null,
    message: '',
}

export const promoReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case CHECK_PROMOCODE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case PROMOCODE_VALID:
            return{
                ...state,
                loading: false,
                promoCode: payload.promoCode,
                discount: payload.discount,
                promoCodeValid: true,
                message: payload.message,
                // error: null,
            }
        case PROMOCODE_INVALID:
            return{
                ...state,
                loading: false,
                promoCodeValid: false,
                error: payload.message,
            }
        case PROMOCODE_ERROR:
            return{
                ...state,
                loading: false,
                promoCodeValid: false, 
                error: payload,
            }
        case RESET_PROMO_CODE:
            return {
                ...state,
                promoCode: '',
                discount: 0,
                promoCodeValid: false,
                error: null,
                message: '',
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state;
    }
}