import React from 'react';
import { Link } from 'react-router-dom';

const NoOrders = () => {
    return (
        <div className="flex flex-col items-center justify-center h-72 md:h-80 lg:h-72">
            <div className="max-w-lg text-center">
                <h2 className="text-3xl font-semibold mb-4">No Orders Found</h2>
                <p className="text-gray-600 mb-6">It seems like you haven't placed any orders yet. Don't worry, you can start exploring our products now!</p>
            </div>
            <Link to="/products" className="bg-black text-white font-semibold py-3 px-8 rounded-md shadow hover:bg-gray-900 transition duration-300">Start Shopping</Link>
        </div>
    );
}

export default NoOrders;