import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MetaData from '../MetaData';
import CartItem from './CartItem';
import { getCartItems } from '../../actions/cartAction';
import Loader from '../Loader';
import Stepper from './Stepper';
import PriceSidebar from './PriceSidebar';
import { getAllPromoCodes } from '../../actions/orderAction';
import { clearErrors, resetPromoCode } from '../../actions/promoAction';
// import DialogBox from '../Product/DialogBox';
// import { formatCurrency } from '../../utils/functions';

const OrderConfirm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector(state => state.user);

    const { cartData, } = useSelector(state => state.cart)
    
    const { loading: dashboardLoading, } = useSelector(state => state.dashboard)
    const { promoCode, promoCodeValid } = useSelector(state => state.promo);
    const { getAllPromoCode, } = useSelector(state => state.orders)

    // const [isDialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        if (isAuthenticated && user?.id) { // Check if user exists and has an id
            const userId = user.id;
            dispatch(getCartItems(userId));
        }
        dispatch(getAllPromoCodes())
        window.scrollTo(0,0)
    }, [dispatch, isAuthenticated, user?.id])

    const validPromoCode = getAllPromoCode && getAllPromoCode.length > 0 && promoCode
    ? getAllPromoCode.find(promo => promo.status === "valid" && promo.promo_code === promoCode)
    : null;

    useEffect(() => {
        const totalCartPrice = cartData && cartData.length > 0 && cartData?.reduce((total, item) => total + (item.product_new_price * item.qty), 0).toFixed(2);
        if (validPromoCode && totalCartPrice < validPromoCode.minimum_order_value && promoCodeValid) {
            dispatch(resetPromoCode());
            dispatch(clearErrors())
        }
    }, [cartData, dispatch, promoCodeValid, validPromoCode,])

    // const minimumOrderAmount = 4;

    // const isOrderEligible = () => {
    //     const countPrice = cartData && cartData.length > 0 && cartData.reduce((sum, item) => sum + (parseFloat(item.product_new_price) * parseInt(item.qty)), 0);
    //     return countPrice > minimumOrderAmount;
    // }

    const handleContinue = () => {
        if(!isAuthenticated){
            return navigate("/login")
        }
        // if(!isOrderEligible()){
        //     setDialogOpen(true)
        //     return
        // }
        navigate('/process/payment');
    };

    // const handleCloseDialog = () => {
    //     setDialogOpen(false)
    // }
    // const handleDialogAction = () => {
    //     navigate("/cart")
    // }
  return (
  
    <div>
        <MetaData title="Order Confirmation" />

        <main className="max-w-8xl mx-auto pt-0 px-2 sm:px-4 lg:px-6 py-4">
            {dashboardLoading ? (
                <Loader />
            ) : (
                <div className="bg-gray-100 rounded-lg shadow-lg p-3 bg flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
                    <div className="flex-1">
                        <Stepper activeStep={2} className="bg-gray-500">
                            <div>
                                {cartData && cartData.length > 0 && cartData?.map((item, index) => (
                                    <CartItem {...item} inCart={false} key={index} />
                                ))}
                            </div>
                                <div className="flex flex-col sm:flex-row justify-between items-center mt-8">
                                    {/* <p className="text-sm text-gray-600 mb-4 sm:mb-0">
                                        An order confirmation email will be sent to{' '}
                                        <span className="font-medium">{user.email}</span>
                                    </p> */}
                                    <button
                                        name='continue'
                                        onClick={handleContinue}
                                        className="bg-green-500 text-white px-6 py-3 w-full font-bold rounded-md hover:bg-green-600 transition-colors duration-300"
                                    >
                                        Continue
                                    </button>
                                </div>
                            
                        </Stepper>
                    </div>
                    {cartData && cartData.length > 0 && <PriceSidebar cartItems={cartData} /> }
                </div>
            )}
        </main>

        {/* <DialogBox 
            open={isDialogOpen}
            handleClose={handleCloseDialog}
            handleAction={handleDialogAction}
            dialogTitle="Attention"
            dialogMessage={`The minimum order amount should be more than ${formatCurrency(minimumOrderAmount)}`}
            cancelButtonName="Upgrade quantity"
            buttonName="Go to Cart"
        /> */}
    </div>

  )
}

export default OrderConfirm;