import {
    ALL_PRODUCTS_FAIL,
    ALL_PRODUCTS_REQUEST,
    ALL_PRODUCTS_SUCCESS,
    CLEAR_ERRORS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
} from "../constants/productConstants";


const initialState = {
    products: [],
    loading: false,
    error: null,
}
export const productsListReducer = (state = initialState, action) => {
    
    let {type, payload} = action;
    switch (type) {
        case ALL_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ALL_PRODUCTS_SUCCESS:
            return { ...state, loading: false, products: payload, error: null, };
        case ALL_PRODUCTS_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;   
    }
}

export const productDetailsReducer = (state = {initialState}, { type, payload }) => {
    switch (type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: payload,
            };
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

