import React from 'react'
import { TextField, InputAdornment, Select, MenuItem, Typography } from "@mui/material"
import 'react-international-phone/style.css';
import {FlagImage, parseCountry, usePhoneInput, defaultCountries, } from 'react-international-phone'

// Filter to include only Latvia (iso2: 'lv')
const latviaCountry = defaultCountries.filter(c => parseCountry(c).iso2 === 'lv')

const MuiPhone = ({ value, onChange, readOnly, ...restProps }) => {
    const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        defaultCountry: "lv",
        value,
        countries: latviaCountry,
        onChange: (data) => { onChange(data.phone) }
    })

    
  return (
    <TextField
        id='phonenumber'
        name='phonenumber'
        label="Phone number"
        variant='outlined'
        color='primary'
        placeholder="Phone number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        type='tel'
        inputRef={inputRef}
        InputProps={{
            startAdornment:(
                <InputAdornment
                    position='start'
                    // style={{ marginRight: '2px', marginLeft: '-8px' }}
                    className='mt-0 ml-0'
                >
                    <Select
                        id='select-country' 
                        name='select-country'
                        MenuProps={{    
                            style: {
                                height: "300px",
                                width: "360px",
                                top: "10px",
                                left: "-34px"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            }
                        }}
                        sx={{
                            width: "max-content",
                            // Remove default outline (display only on focus)
                            fieldset: {
                                display: "none"
                            },
                            '&.Mui-focused:has(div[aria-expanded="false"])': {
                                fieldset: {
                                    display: "block"
                                }
                            },
                            // Update default spacing
                            ".MuiSelect-select": {
                                padding: "8px",
                                paddingRight: "24px !important"
                            },
                            svg: {
                                right: 0
                            }
                        }}
                        value={country.iso2}
                        onChange={(e) => setCountry(e.target.value)}
                        renderValue={(value) => (
                          <FlagImage iso2={value} id='flag-img'  className='flex mt-0  pt-0' />
                        )}
                     >
                        {latviaCountry.map((c) => {
                            const country = parseCountry(c);
                            return (
                              <MenuItem key={country.iso2} value={country.iso2} id='country-name'>
                                <FlagImage
                                    iso2={country.iso2}
                                    //  style={{ marginRight: '8px' }}
                                    className='mr-4'
                                    id='flag-image'
                                />
                                <Typography className='pr-2 mr-2'>{country.name}</Typography>
                                <Typography className='text-gray-500 pr-4 '>+{country.dialCode}</Typography>
                              </MenuItem>
                            );
                        })}

                    </Select>
                </InputAdornment>
            ),
            readOnly: readOnly
        }}
        {...restProps}
    />
  )
}

export default MuiPhone
