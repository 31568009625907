import axios from 'axios';
import { CLEAR_ERRORS, USER_DASHBOARD_FAIL, USER_DASHBOARD_REQUEST, USER_DASHBOARD_SUCCESS } from '../constants/dashboardConstant';

const API = process.env.REACT_APP_API_URL

export const getUserDashboardData = () => async(dispatch) => {
    try{

        dispatch({type: USER_DASHBOARD_REQUEST})

        const reqBody = {
            operation: "getDashboardData"
        }

        const { data } = await axios.post(API, reqBody, 
            {
                headers:{
                    "Content-Type": "application/json" ,
                }
            }
        )
        dispatch({
            type: USER_DASHBOARD_SUCCESS,
            payload: data
        })
        
    }catch(error){
        dispatch({
            type: USER_DASHBOARD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => (dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}