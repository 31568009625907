import { ADD_TO_WISHLIST, ADD_TO_WISHLIST_FAILED, ADD_TO_WISHLIST_REQUEST, FAILED_TO_REMOVE_FROM_WISHLIST, REMOVE_FROM_WISHLIST, WISHLIST_DETAIL_FAILED, WISHLIST_DETAIL_REQUEST, WISHLIST_DETAIL_SUCCESS } from "../constants/wishlistConstants"

const initialState = {
    wishlistItems: JSON.parse(localStorage.getItem('wishlistItems')) || [],
    message: null,
    loading: false,
    error: null,
}
export const wishlistReducer = (state = initialState, {type, payload}) => {
    switch (type){
        case ADD_TO_WISHLIST_REQUEST:
        case WISHLIST_DETAIL_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            }
        case ADD_TO_WISHLIST:
            return{
                loading: false,
                wishlistItems: [...state.wishlistItems, { product_id: payload.product_id }],
                message: payload.message,
                error: null
            }
        case WISHLIST_DETAIL_SUCCESS:
            return {
                loading: false,
                wishlistItems: payload,
                message: null,
                error: null
            } 
        case REMOVE_FROM_WISHLIST:
            return {
                ...state,
                loading: false,
                wishlistItems: state.wishlistItems.filter((item) => item.product_id !== payload.product_id),
                message: payload.message,
                error: null,
            }

        case ADD_TO_WISHLIST_FAILED:
        case WISHLIST_DETAIL_FAILED:
        case FAILED_TO_REMOVE_FROM_WISHLIST:
            return{
                loading: false,
                wishlistItems: null,
                error: payload,
                message: null,
            }
        default:
            return state;
    }

}