import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from './Stepper';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import MetaData from '../MetaData';
import { FormControlLabel, Radio, RadioGroup, Tooltip, } from '@mui/material';

import React from 'react'
import { getShippingAddress, saveShippingAddress, setDeliveryDetails } from '../../actions/shippingAction';
import Loader from '../Loader';
import MuiPhone from '../Other/MuiPhone';

const Shipping = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSelector(state => state.user)

    const { isAuthenticated, shippingAddress, loading } = useSelector(state => state.shippingAddress)

    const { loading: dashboardLoading, } = useSelector(state => state.dashboard)
    // const getPhoneNo = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : ""

    const [addressType, setAddressType] = useState('new');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [address, setAddress] = useState('');
    const [city] = useState('Riga');
    const [country] = useState('Latvia');
    const [pincode, setPincode] = useState('');
    const [mobileNo, setMobileNo] = useState('');

    useEffect(() => {
        if(shippingAddress && shippingAddress.length > 0){
            setSelectedAddress(shippingAddress[0].id);
        }
    }, [shippingAddress])

    useEffect(() => {
        if(isAuthenticated && user?.id){
            const userId = user.id;
            dispatch(getShippingAddress(userId))
        }
        window.scrollTo(0,0)
    }, [dispatch, isAuthenticated, user?.id])

    useEffect(() => {
        if(addressType === 'existing'){
            const selectedAddr = shippingAddress.find(addr => addr.id === selectedAddress)
            if(selectedAddr){
                setMobileNo(selectedAddr.mobile_no);
            }
        } else {
            setMobileNo('')
        }
    }, [addressType, selectedAddress, shippingAddress])

    const shippingSubmit = async(e) => {
        e.preventDefault();

        if(addressType === 'new' && mobileNo.length < 8 ){
            enqueueSnackbar("Invalid Phone Number", {variant: "error"})
            return;
        }
        try{
            let selectedAddr;
            if (addressType === 'existing') {
                selectedAddr = shippingAddress && shippingAddress.length > 0 && shippingAddress.find(addr => addr.id === selectedAddress);
                
                if (!selectedAddr) {
                    enqueueSnackbar("Please select a valid address", { variant: "error" });
                    return;
                }
                dispatch(setDeliveryDetails(selectedAddr.address, selectedAddr.mobile_no));
                localStorage.setItem("deliveryDetails", JSON.stringify({address: selectedAddr.address, mobile_no: selectedAddr.mobile_no }))
                // Do something with the selected existing address if needed
            } else {
                selectedAddr = `${address}, ${city}, ${country} - ${pincode}`
                
                dispatch(saveShippingAddress(user.id, address, city, country, pincode, mobileNo));
                localStorage.setItem("deliveryDetails", JSON.stringify({ address: selectedAddr, mobile_no: mobileNo }))
            }

            navigate('/order/confirm');
        }catch(error){
            console.log("Failed to save shipping address => ",error);
            enqueueSnackbar(`Failed to save shipping address`, { variant: "error" });
        }
        
    }

  return (

    <div>
        <MetaData title="Shipping Details" />
        <main className="max-w-8xl mx-auto pt-0 px-2 sm:px-4 lg:px-6 py-4">
            {dashboardLoading ? (
                <Loader/>
            ): (
                <div className="bg-gray-100 rounded-lg shadow-lg p-3 bg flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
                    <div className="flex-1">
                        <Stepper activeStep={1} className="px-8">
                            {loading ? <Loader/> :(
                                <form onSubmit={shippingSubmit} autoComplete="off" className="bg-white rounded-lg p-4 space-y-8 md:space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="address-type" name="address-type" value={addressType} onChange={(e) => setAddressType(e.target.value)}>
                                                <FormControlLabel value="new" control={<Radio />} label="Add New Address" />
                                                {shippingAddress && shippingAddress.length > 0 &&
                                                    <FormControlLabel value="existing" control={<Radio />} label="Choose Existing Address" />
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                        {addressType === 'existing' && shippingAddress && shippingAddress.length > 0 &&
                                            <FormControl fullWidth>
                                                <Select labelId="address-select-label" name='address-select' id="address-select" className='pr-8 pl-1' value={selectedAddress} onChange={(e) => setSelectedAddress(e.target.value)}>
                                                    {shippingAddress.map(addr => (
                                                        <MenuItem key={addr.id} value={addr.id} id='address-option'>
                                                            <Tooltip title={addr.address} arrow>
                                                                <div>
                                                                    <span className="truncate">{addr.address}</span>
                                                                </div>
                                                            </Tooltip>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        }
                                        {addressType === 'new' &&
                                            <>
                                                <TextField id="address" label="Delivery Address" autoComplete='off' value={address} onChange={(e) => setAddress(e.target.value)} variant="outlined" required fullWidth />
                                                <TextField id="city" label="City" autoComplete='off' value={city} variant="outlined" required fullWidth disabled />
                                                <TextField id="postalcode" autoComplete='off' label="Postalcode" value={pincode} onChange={(e) => setPincode(e.target.value)} variant="outlined" required fullWidth />
                                            </>
                                        }
                                            <TextField id="country" autoComplete='off' label="Country" value={country} variant="outlined" required fullWidth disabled />
                                            {/* <TextField id="phoneNo" type='number' label="Phone No" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} variant="outlined" required fullWidth /> */}
                                            {/* {addressType === 'existing' ? (
                                                <TextField id="phoneNo" type='text' label="Phone No" value={selectedMobileNo} variant="outlined" fullWidth readOnly />
                                            ) : (
                                                <MuiPhone value={mobileNo} onChange={(newPhoneNo) => setMobileNo(newPhoneNo)}  required fullWidth />
                                            )} */}

                                            <MuiPhone value={mobileNo} onChange={(newPhoneNo) => setMobileNo(newPhoneNo)}  required fullWidth readOnly={addressType === 'existing'} />
                                        
                                    </div>
                                    <button type="submit"name='save-deliver' className="bg-green-500 w-full py-4 text-white font-bold rounded-lg hover:bg-green-600 transition duration-300">
                                        Save and Deliver Here
                                    </button>
                                </form>
                            )}
                        </Stepper>
                    </div>
                </div>
            )}
        </main>
    </div>

  )
}

export default Shipping