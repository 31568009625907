import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchBar = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const [searchText, setSearchText] = useState("")

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchText.trim()) {
            navigate(`/search?q=${searchText}`);
        }else {
            navigate('/products');
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('q') || '';
        setSearchText(query);
    }, [location.search]);

    useEffect(() => {
        return () => {
            setSearchText('');
        };
    }, []);

  return (
    <form 
        className="border-2 border-gray-50 focus-within:border-gray-100 focus-within:bg-transparent flex px-3 mt-2 mb-2 rounded-full h-10 lg:w-1/3 mx-auto max-lg:mt-4 items-center"
        onSubmit={handleSearch}
    >
        
        <SearchIcon className="text-gray-50 h-3 w-3 fill-gray-50 mr-3 rotate-90" />
        <input 
            type='search'
            placeholder='Search that you want...'
            className='flex-grow outline-none bg-transparent text-white font-semibold text-[15px] placeholder-gray-200'
            value={searchText}
            autoComplete='off'
            name='search'
            onChange={(e) => setSearchText(e.target.value)}
        />
        <button 
            name='search'
            className=" ml-2 h-7 text-sm text-gray-50 font-bold px-2 border-0 rounded-xl cursor-pointer hover:bg-white hover:text-gray-500  transition duration-300 ease-in-out"
        >
            Search
        </button>
    </form>
  )
}

export default SearchBar
