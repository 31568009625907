import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { clearErrors, getSingleProduct } from '../../actions/productAction'
import { addItemsToCart, getCartItems } from '../../actions/cartAction';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import MetaData from '../MetaData';
import { Favorite, FavoriteBorder, ShoppingCart } from '@mui/icons-material';
import RatingStar from "../RatingStar";
import { addToWishList, getWishlistItems, removeItemFromWishlist } from '../../actions/wishlistAction';
import { getUserDashboardData } from '../../actions/dashboardAction';
import DialogBox from './DialogBox';
import defaultImg from "../../assets/images/defaultImage.jpg";
import { formatCurrency, handleShare, stockStatus } from '../../utils/functions';
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';

const SingleProduct = () => {

  const { pid } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()

  const { wishlistItems } = useSelector(state => state.wishlist)
  const { isAuthenticated, user } = useSelector(state => state.user)
  const { dashboardData, loading: dashboardLoading, error } = useSelector(state => state.dashboard)
  const { cartData } = useSelector((state) => state.cart);

  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleAddToCart = async(id, product_id) => {
    if(!isAuthenticated){
      enqueueSnackbar("Please login to add products to your cart.", { variant: "warning" });
      return;
    }
    await dispatch(addItemsToCart(id, product_id));
    await dispatch(getCartItems(user.id))
    enqueueSnackbar('Item added to cart', {variant:"success",})
  }

  const itemInCart = cartData.some((i) => i.product_id.toString() === pid.toString())

  const goToCart = () => {
    navigate('/cart')
  }
  
  const addToWishlistHandler = async(productId) => {
    if(!isAuthenticated){
      enqueueSnackbar("Please login to add products to your wishlist.", { variant: "warning" });
      return;
    }
    if(isAuthenticated && user?.id){
      const userId = user.id;
      if(wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === productId)){
        await dispatch(removeItemFromWishlist(userId, productId))
        enqueueSnackbar('Item removed from wishlist', {variant:"success"})
      }else{
        await dispatch(addToWishList(userId, productId))
        enqueueSnackbar('Item added to wishlist', {variant:"success"})
      }
      dispatch(getWishlistItems(userId))
    }
  }
  
  const handleLogin = () => {
    setLoginDialogOpen(false)
    navigate("/login")
  }

  const inventory = dashboardData && dashboardData.inventory && dashboardData.inventory.find((i) => i.product_id.toString() === pid.toString() );

  useEffect(() => {
    if(error){
      dispatch(clearErrors());
      enqueueSnackbar(error, { variant: "error" });
    }
    dispatch(getSingleProduct(pid))
    if (isAuthenticated && user?.id) { // Check if user exists and has an id
      const userId = user.id;
      dispatch(getCartItems(userId));
      dispatch(getWishlistItems(userId))
    }
    dispatch(getUserDashboardData())

    window.scrollTo(0,0)
    
  }, [dispatch, enqueueSnackbar, error, pid, isAuthenticated, user?.id ])

  useEffect(() => {
    if(inventory){
      setSelectedImageIndex(0);
    }
  }, [inventory])
  
  if(!inventory){
    return dashboardLoading || !inventory ? <Loader/> : inventory
  }

  const productImages = [
    inventory?.product_img1,
    inventory?.product_img2,
    inventory?.product_img3,
    inventory?.product_img4,
  ].filter(Boolean); // filter out any undefined images

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % productImages.length)
  }

  const handlePreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length)
  }

  return (

    <div className="font-sans tracking-wide p-4 lg:max-w-7xl max-w-2xl max-lg:mx-auto">
      {dashboardLoading ? <Loader /> : (
        <div>
          {dashboardData && dashboardData?.inventory && (
            <div>
              <MetaData
                title={`Trusty Indian | Buy ${inventory.product_name} Online with Indian test at Best Price in Riga-Latvia`}
                description={`Trustyindian brings ${inventory.description} for sale in Riga. So order now and enjoy it with real Indian test!`}
                url={window.location.href}
                image={inventory.product_img1}
                canonical={window.location.href}
                keywords={`Trustyindian, Trusty Indian, buy Indian ${inventory.product_name} Online in Riga Latvia or Europe, Surati ${inventory.product_name} in Riga Latvia, Order Indian food online in Riga, order surati food in riga, where to buy indian food in riga, indian rabadi in riga, buy indian khakhra in Riga, Meggi in Riga, Aloo sev in Riga Latvia, where to buy surati bhakhrwadi in Riga latvia, soan papdi in riga latvia, where to buy soan papdi in riga, Haldiram soan papdi in riga latvia, Indian breakfast food near by me, Indian papad in riga, buy surati local food in riga, buy indian local food in riga, indian food in riga, indian food near me,`}
              />

              <div className="grid grid-cols-1 lg:grid-cols-6 gap-8">

                {/* Image slider */}
                <div className="lg:col-span-3 text-center">
                  <div className="h-54 lg:h-full p-2 relative border-2">
                    <img 
                      src={productImages[selectedImageIndex]}
                      alt={inventory.product_name}
                      title={inventory.product_name}
                      className="w-full h-[254px] rounded object-contain lg:h-[350px] lg:object-contain"
                      onError={(e) => {
                        e.target.src = defaultImg
                      }}
                    />

                    <button
                      onClick={handlePreviousImage}
                      className="absolute top-40 lg:top-48 left-0 transform -translate-y-1/2 bg-gray-500 text-white p-2 rounded-full"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={handleNextImage}
                      className="absolute top-40 lg:top-48 right-0 transform -translate-y-1/2 bg-gray-500 text-white p-2 rounded-full"
                    >
                      &gt;
                    </button>

                    <hr className="my-4 box-border" />
                    <div className="flex flex-row gap-3 items-center justify-center mx-auto mt-4">
                      {productImages && productImages?.map((image, index) => (
                        <div 
                          key={index}
                          className="cursor-pointer p-1 relative before:absolute before:inset-0 before:bg-black before:opacity-20 before:rounded"
                          onClick={() => setSelectedImageIndex(index)}
                        >
                          <img
                            src={image}
                            alt={inventory.product_name}
                            title={inventory.product_name}
                            className="w-20 h-16 object-cover"
                            onError={(e) => e.target.src = defaultImg}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Image slider */}
                
                <div className="lg:col-span-3 gap-4 items-center">
                  <div className="flex flex-row items-start gap-4">
                    <div>
                      <h2 className="text-2xl font-extrabold text-gray-800">{inventory.product_name}</h2>

                      <div className="flex space-x-1 mt-4">
                        <RatingStar rating={4.5} />
                        <span className="text-gray-500">4.5 (243)</span>
                      </div>

                      <div className="flex space-x-1 mt-4">
                        <p className="text-gray-600 px-2.5 py-1.5 bg-gray-100 text-md rounded-xl flex items-center">{stockStatus(inventory.stock)}</p>
                      </div>
                    </div>

                    <div className="ml-auto flex gap-3">
                      <Tooltip title="Add to Wishlist" arrow>
                        <button 
                          type="button" 
                          name='wishlist'
                          id='wishlist'
                          onClick={() => addToWishlistHandler(inventory.product_id)}
                          className="px-2.5 py-1.5 bg-pink-100 text-sm text-pink-600 rounded flex items-center justify-center hover:shadow-md"
                        >
                          {wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === inventory.product_id) ? (
                            <div>
                              <Favorite />
                              {/* <span className="ml-2">Remove from Wishlist</span> */}
                            </div>
                            ) : (
                              <div>
                                <FavoriteBorder />
                                {/* <span className='ml-2'>Add to Wishlist</span> */}
                              </div>
                            )
                          }
                        </button>
                      </Tooltip>
                      
                      <Tooltip title="Share" arrow>
                        <button 
                          type="button" 
                          name='share'
                          id='sharebtn'
                          className="px-2.5 py-1.5 bg-gray-100 text-xs text-gray-800 rounded flex items-center justify-center hover:shadow-md"
                          onClick={() => handleShare(inventory.product_name, inventory.product_name, window.location.href, enqueueSnackbar)}
                        >
                          <ShareIcon/>
                        </button>
                      </Tooltip>
                    </div>
                    
                  </div>

                  <hr className="my-4" />
                  <div>
                    <h3 className="text-xl font-bold text-gray-800">Price</h3>
                    <div className="flex items-center gap-2">
                      <p className="text-gray-800 text-lg font-bold mt-2">{formatCurrency(inventory.product_new_price)}</p>
                      <p className="mt-2 text-md text-gray-500 line-through decoration-2 decoration-red-500">{formatCurrency(inventory.product_old_price)}</p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h3 className="text-xl font-bold text-gray-800">Delivery</h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                      Delivery within 24 hours
                    </div>
                  </div>

                <hr className="my-4" />
                <div className="flex flex-col lg:flex-row gap-4">
                  {inventory.stock > 0 ? (
                    <button 
                      type="button"
                      name={`${itemInCart ? "Go to Cart" : "Add to Cart"}`}
                      className="min-w-[200px] w-full px-4 py-2.5 border bg-green-500 items-center justify-center text-gray-50 text-md font-semibold rounded-md hover:shadow-md hover:bg-primary-lightGreen"
                      onClick={ isAuthenticated ? (itemInCart ? goToCart : () => handleAddToCart(user.id, inventory.product_id)) : () => setLoginDialogOpen(true)}
                    >
                      <ShoppingCart className="mr-2" />
                      {itemInCart ? "Go to Cart" : "Add to Cart"}
                    </button>
                  ) : (
                    <p className="w-full items-center justify-center text-center px-4 py-2.5 border bg-green-400 text-gray-50 text-md font-semibold rounded-md hover:cursor-not-allowed">
                      Sold Out
                    </p>
                  )}
                </div>
                </div>
              </div>
              
              <div className="mt-6 max-w-2xl">
                {/* Product description */}
                <div className="mt-6">
                  <h3 className="text-xl font-bold text-gray-800">Product Description</h3>
                  <p className="text-sm text-gray-600 mt-2 text-pretty text-justify">{inventory.description}</p>
                </div>
                {/* Product description */}
              </div>
            </div>
          )}
        </div>
      )}
      <DialogBox
        dialogTitle="Login Required"
        dialogMessage="Please login to add products to your cart"
        open={loginDialogOpen}
        handleClose={() => setLoginDialogOpen(false)}
        handleAction={handleLogin}
        buttonName="Login"
      />
    </div>
  );
};

export default SingleProduct;