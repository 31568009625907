import axios from 'axios'
import { ADD_TO_CART, ADD_TO_CART_FAILED, ADD_TO_CART_REQUEST, CART_DETAIL_FAILED, CART_DETAIL_REQUEST, CART_DETAIL_SUCCESS, EMPTY_CART, FAILED_TO_REMOVE_FROM_CART,  REMOVE_FROM_CART, SAVE_SHIPPING_INFO, UPDATE_QUANTITY_FAILED, UPDATE_QUANTITY_REQUEST, UPDATE_QUANTITY_SUCCESS } from '../constants/cartConstants'

const API = process.env.REACT_APP_API_URL

export const addItemsToCart = (id, product_id, quantity = 1) => async(dispatch, getState) => {
    try{
        dispatch({type: ADD_TO_CART_REQUEST})

        const reqData = {
            operation: "addCart",
            uid: id,
            product_id: product_id,
            qty: quantity
        }
        await axios.post(`${API}`, reqData, 
            {
                headers:{
                    "Content-Type":"application/json"
                }
            }
        )

        dispatch({
            type: ADD_TO_CART,
            payload: { product_id, quantity },
        })

    }catch(error){
        dispatch({
            type: ADD_TO_CART_FAILED,
            payload: error.response.data.message
        })
    }
    
}

export const getCartItems = (id) => async(dispatch) => {
    try{
        
        dispatch({type: CART_DETAIL_REQUEST})

        const reqData = {
            operation: "getCartDataByUserId",
            uid: id
        }

        const { data } = await axios.post(API, reqData,
            {
                headers:{
                    "Content-Type": "application/json"
                }
            }
        )        
        dispatch({
            type: CART_DETAIL_SUCCESS,
            payload: data,
        })

        localStorage.setItem('cartData', JSON.stringify(data))

    }catch(error){
        console.log("Error in getCartItem-> ",error);
        dispatch({
            type: CART_DETAIL_FAILED,
            payload: error.response.data.message
        })
    }
}

export const removeItemsFromCart = (id, product_id) => async(dispatch, getState) => {
    try{
        const reqData = {
            operation: "removeFromCart",
            uid: id,
            product_id: product_id
        }

        await axios.post(`${API}`, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })
        dispatch({
            type: REMOVE_FROM_CART,
            payload: {product_id}
        })

        localStorage.removeItem("cartData")
        
    }catch(error){
        dispatch({
            type: FAILED_TO_REMOVE_FROM_CART,
            payload: error.response.data.message
        })
    }
}

export const updateProductQuantityInCart = (id, product_id, quantity = 1)  => async(dispatch, getState) => {
    try{
        dispatch({type: UPDATE_QUANTITY_REQUEST})

        const reqData = {
            operation: "updateQty",
            uid: id,
            product_id: product_id,
            qty: quantity,
        }

        await axios.post(`${API}`, reqData, 
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        dispatch({
            type: UPDATE_QUANTITY_SUCCESS,
            payload: { product_id, quantity }
        })

    }catch(error){
        dispatch({
            type: UPDATE_QUANTITY_FAILED,
            payload: error.response.data.message
        })
    }

    // update localStorage after increasing quantity
    // localStorage.setItem("cartData", JSON.stringify(getState().cart.cartItems))
}

export const emptyCart = () => async(dispatch) => {
    dispatch({type: EMPTY_CART})
}

export const saveShippingInfo = (data) => async(dispatch) => {
    dispatch({
        type: SAVE_SHIPPING_INFO,
        payload: data,
    });
}