import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import Loader from './components/Loader';
const root = ReactDOM.createRoot(document.getElementById('root'));

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID


root.render(
  <React.StrictMode>
    <React.Suspense fallback="">
      <GoogleOAuthProvider clientId={googleClientId}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={2} anchorOrigin={{vertical: "bottom", horizontal:"right"}} >
            <Router>
              <App />
            </Router>
          </SnackbarProvider>
        </Provider>
      </GoogleOAuthProvider>
    </React.Suspense>
  </React.StrictMode>
);


