import { applyMiddleware, combineReducers, createStore } from "redux";
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from "@redux-devtools/extension";
import { userReducer } from "../src/reducers/userReducer";
import { cartReducer,  } from "./reducers/cartReducer";
import { productDetailsReducer, productsListReducer } from "./reducers/productReducer";
import { orderReducer } from "./reducers/orderReducer";
import { wishlistReducer } from "./reducers/wishlistReducer";
import { dashboardReducer } from "./reducers/dashboardReducer";
import { shippingAddressReducer } from "./reducers/shippingReducer";
import { searchReducer } from "./reducers/searchReducer";
import { calculateDeliveryCharges } from "./utils/functions";
import { promoReducer } from "./reducers/promoReducer";


const reducer = combineReducers({
    user: userReducer,
    productList: productsListReducer,
    productDetails: productDetailsReducer,
    wishlist: wishlistReducer,
    dashboard: dashboardReducer,
    orders: orderReducer,
    cart: cartReducer,

    shippingAddress: shippingAddressReducer,
    search: searchReducer,
    promo: promoReducer,
})

let initialState = {
    user: {
        loading: false,
        isAuthenticated: JSON.parse(localStorage.getItem('token')) ? true : false,
        user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
        error: null,
    },
    orders:{
        priceBar: {
            taxPercentage: 0,
            deliveryCharges: calculateDeliveryCharges()
        }
    },
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)


export default store;