import axios from "axios";
import { FAILED_TO_GET_SHIPING_ADDRESS, FAILED_TO_SAVE_SHIPPING_ADDRESS, REQUEST_TO_GET_SHIPPPING_ADDRESS, SAVE_SHIPPING_ADDRESS_SUCCESS, SET_DELIVERY_DETAILS, SUCCESS_TO_GET_SHIPING_ADDRESS } from "../constants/shippingConstants";


const API = process.env.REACT_APP_API_URL

export const saveShippingAddress = (id, address, city, country, pincode, mobile_no) => async(dispatch) => {
    try{
        const fullAddress = `${address}, ${city}, ${country}-${pincode}`

        const reqData = {
            operation: "addAddress",
            uid: id,
            address: fullAddress,
            mobile_no: mobile_no
        }

            const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })
        // localStorage.setItem('shippingAddress', JSON.stringify(shippingAddress))

        dispatch({
            type: SAVE_SHIPPING_ADDRESS_SUCCESS,
            payload: {
                message: data.message,
                address: fullAddress,
                mobile_no
            },
        })

        // Set the delivery details
        dispatch(setDeliveryDetails(fullAddress, mobile_no)) // Set the delivery details immediately after saving
    }catch(error){
        dispatch({
            typpe: FAILED_TO_SAVE_SHIPPING_ADDRESS,
            payload: error,
        })
    }
}

export const getShippingAddress = (id) => async(dispatch) => {
    try{

        dispatch({type: REQUEST_TO_GET_SHIPPPING_ADDRESS})
        
        const reqData = {
            operation: "getUserAllAddress",
            uid: id
        }
        const {data} = await axios.post(API, reqData, {
            headers:{
                'Content-Type': "application/json"
            }
        })
        // localStorage.setItem('allShippingAddress', JSON.stringify(data.addresses))
        dispatch({
            type: SUCCESS_TO_GET_SHIPING_ADDRESS,
            payload: data.addresses
        })
    }catch(error){
        console.log("Error while  fetch shippping address: ",error);
        dispatch({
            type: FAILED_TO_GET_SHIPING_ADDRESS,
            payload: error
        })
    }
}

export const setDeliveryDetails = (address, mobileNo) => async(dispatch) => {
    dispatch({
        type: SET_DELIVERY_DETAILS,
        payload: {address, mobileNo}
    })
}