import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const OrderConfirmation = ({ open, onClose }) => {

  const [time, setTime] = useState(5)

  useEffect(() => {
    let timer;

    if(open){
      timer = setInterval(() => {
        setTime(prevSeconds => prevSeconds - 1)
      }, 1000)
    }
    return () => clearInterval(timer);
  }, [open])

  useEffect(() => {
    if(time === 0){
      onClose();
    }
  }, [time, onClose])


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="bg-gray-200 p-4">Order Confirmed</DialogTitle>
      <DialogContent className="p-4">
        <div className="text-center">
          <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto mb-6">
            <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
          </svg>
          <h1 className="text-3xl font-bold mb-4">Order Placed</h1>
          <p className="text-gray-700 mb-4">Your order has been confirmed. Thank you for shopping with us!</p>
          <p className="text-gray-600 mb-6">We've received your order and will process it shortly.</p>
          <p className="text-gray-600">Redirecting in {time} seconds...</p>
        </div>
      </DialogContent>
      <DialogActions className="bg-gray-200 p-3">
        <Link onClick={onClose} variant="contained"  className="px-4 py-3 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition-colors duration-300">
          Continue Shopping
        </Link>
      </DialogActions>
    </Dialog>
  )
}

export default OrderConfirmation;
