import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency, formatDate, formatPhoneNumber, getStatusColor } from '../../utils/functions';
import defaultImg from "../../assets/images/defaultImage.jpg";
import CancelOrder from './CancelOrder';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const OrderItem = ({ orderItem }) => {

    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { order_id, order_status, order_date, delivery_address, mobile_no, ordered_product_array, total_amount } = orderItem

    const orderProductArray = JSON.parse(ordered_product_array);    


    const handleCancelOrder = async() => {
        if(order_status === 'Canceled'){
            setDialogMessage(`Order with order id ${order_id} is already cancelled.`);
            setIsDialogOpen(true);
            return;
        }
        if (order_status === 'Delivered') {
            setDialogMessage(`Order with order id ${order_id} is already delivered. You cannot cancel it.`);
            setIsDialogOpen(true);
            return;
        }
        if (order_status === 'Returned') {
            setDialogMessage(`Order with order id ${order_id} is already returned. You cannot cancel it.`);
            setIsDialogOpen(true);
            return;
        }
        setIsCancelModalOpen(true);
    }

    const handleCloseCancelModal = () => {
        setIsCancelModalOpen(false);
    };
    
    useEffect(() => {
        window.scrollTo(0,0)
    },[])


  return (
    
    <div className="bg-white rounded-lg shadow-md mb-6">
        <div className="pt-0 p-2">
        <div className={`${getStatusColor(order_status)} text-white px-3 py-4 flex items-center justify-between rounded-t-lg`}>
            <p className="text-gray-200 font-bold">Order No: {order_id}</p>
            <span className="text-md text-white font-semibold py-1 px-3 rounded-full">{order_status}</span>
        </div>
            {orderProductArray && orderProductArray.length > 0 && orderProductArray.map((product) => (
                <Link 
                    to={`/order-details/${order_id}`} 
                    key={product.product_id} 
                    className="group flex flex-col md:flex-row items-left px-3 md:justify-between border-b border-gray-200 py-4 hover:bg-gray-100 transition duration-300"
                >
                    <div className="flex items-center mb-2 md:mb-0">
                        <img 
                            src={product.product_img1} 
                            alt={product.product_name} 
                            title={product.product_name}
                            className="w-16 h-16 object-contain mr-4" 
                            loading='lazy'
                            onError={(e) => {
                                e.target.src = defaultImg
                            }}
                        />
                        <div>
                            <p className="text-gray-800 font-semibold">{product.product_name}</p>
                            <p className="text-gray-600">Qty: {product.qty}</p>
                            <p className="text-gray-600">Price: {formatCurrency(product.product_new_price)}</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-gray-800 font-semibold">{formatCurrency(product.product_new_price * product.qty)}</p>
                    </div>
                </Link>
            ))}

            {/* Heading for Discount */}
            {/* <div className='flex justify-between mt-o px-6 py-1'>
                <h3 className="text-gray-800 font-semibold">Discount</h3>
            </div> */}
            {/* Heading for total amount */}
            <div className="flex justify-between mt-0  px-6 py-4 ">
                <h3 className="text-gray-800 font-semibold">Total Price:</h3>
                <p className="text-gray-800 font-semibold">{ formatCurrency(total_amount)}</p>
            </div>
            {/* Address and Buttons */}
            <div className="flex flex-col md:flex-row items-start px-6 pt-0 py-2 md:items-center justify-between mt-0">
                <div className="mb-4 md:mb-0">
                    <p className="text-gray-600">Order Date: {formatDate(order_date)}</p>
                    <p className="text-gray-600">Mobile No: {formatPhoneNumber(mobile_no)}</p>
                    <p className="text-gray-600">Delivery Address: {delivery_address}</p>
                </div>
                <div className="flex mt-4 md:mb-0 md:mt-0">
                    {/* <button className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-900 duration-300 mr-2">
                        <Link to={`/track-order/${order_id}`}>
                            <span className="hover:text-white">Track Order</span>
                        </Link>
                    </button> */}
                    <button 
                        name='cancel-order'
                        onClick={handleCancelOrder} 
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 duration-300"
                    >
                        <span className="hover:text-white">Cancel Order</span>
                    </button>
                     {/* Cancel Order Confirmation Modal */}
                    {isCancelModalOpen && (
                        <CancelOrder orderId={order_id} onClose={handleCloseCancelModal} />
                    )}
                </div>
            </div>
        </div>
         <Dialog open={isDialogOpen} >
            <DialogTitle className="bg-gray-100 p-4">{order_status}</DialogTitle>
            <DialogContent className="p-4">
                <p className="text-gray-700">{dialogMessage}</p>
            </DialogContent>

            <DialogActions className="bg-gray-100 p-4">
                {/* <Button onClick={handleClose} variant="contained" color="secondary">Cancel</Button> */}
                <Button onClick={() => setIsDialogOpen(false)} variant="contained" color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default OrderItem;