import React, { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('Form submitted successfully!');
                // Reset form fields after successful submission
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            } else {
                console.error('Form submission failed.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (

        <div className="bg-gray-100 py-10 p-8 rounded-lg max-w-5xl mx-auto">
            <h2 className="text-2xl font-bold text-center text-green-500 mb-6">Contact Us</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <input 
                    type='text' 
                    placeholder='Name' 
                    name="name" 
                    autoComplete='off' 
                    value={formData.name} 
                    onChange={handleChange} 
                    className="w-full p-3 rounded-md bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500" 
                />
                <input 
                    type='email' 
                    placeholder='Email' 
                    name="email" 
                    autoComplete='off' 
                    value={formData.email} 
                    onChange={handleChange} 
                    className="w-full p-3 rounded-md bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <textarea 
                    placeholder='Message' 
                    rows="6" name="message" 
                    autoComplete='off' 
                    value={formData.message} 
                    onChange={handleChange} 
                    className="w-full p-3 rounded-md bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 h-32"
                ></textarea>

                <button 
                    type="submit" 
                    name='submit' 
                    id='submit-btn'
                    className="w-full py-3 bg-green-500 text-white font-bold rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                >Submit</button>
            </form>
        </div>

    );
}

export default Contact;
