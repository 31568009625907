import React, { useEffect } from 'react'
import Contact from '../Contact/Contact'

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
  return (
    <div className="bg-white py-2 px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto text-center">
            <h1 className="text-3xl font-bold text-green-500 mb-6">About Us</h1>
            <p className="text-lg mb-4">
                Welcome to our Trustyindian.com store offering authentic Indian products including Namkeen, Sweets, and much more. We bring the real taste of India to Riga, Latvia.
            </p>
            <p className="text-lg mb-4">
                At our store, you'll find a wide range of traditional Indian snacks and sweets, carefully curated to provide you with the most authentic flavors. Whether you're craving a spicy snack or a sweet treat, we have something for everyone.
            </p>
            <p className="text-lg mb-4">
                Our mission is to spread the joy of Indian cuisine and culture to the people of Riga and beyond. We are committed to providing high-quality products and exceptional customer service to ensure your satisfaction.
            </p>
        </div>

        <div className="mt-12">
            <Contact />
        </div>
    </div>
  )
}

export default AboutUs
