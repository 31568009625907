import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrder, myOrders } from '../../actions/orderAction';    

const CancelOrder = ({ orderId, onClose }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const trigger = useRef(null);
    const modal = useRef(null);

    const { user } = useSelector(state => state.user);

    const handleCancelOrder = async() => {
        if (!reason) {
            // Show error message for missing reason
            enqueueSnackbar("Please select a reason for cancellation.", {variant: "error"});
            return;
        }
        if (reason === 'other' && !otherReason) {
            enqueueSnackbar('Please provide a reason for cancellation.', { variant: 'error' });
            return;
        }

        // const cancellationReason = reason === "other" ? otherReason : reason;

        try{
            await dispatch(cancelOrder(user.id, orderId))
            await dispatch(myOrders(user.id))
            enqueueSnackbar('Order canceled successfully.',  { variant: 'success' })
            onClose();

        }catch(error){
            console.error('Error canceling order:', error);
            enqueueSnackbar('Failed to cancel order. Please try again later.', { variant: 'error' });
        }

    };

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!modal.current) return;
            if (
              !modalOpen ||
              modal.current.contains(target) ||
              trigger.current.contains(target)
            )
              return;
            setModalOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    })

    //Close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!modalOpen || keyCode !== 27) return;
            setModalOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    })

    return (

        <div className="fixed inset-0 px-2 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">Cancel Order Confirmation</h3>
                
                <p className='py-2'>Are you sure you want to cancel order #{orderId}?</p>

                <div className="mb-4">
                    <label htmlFor="cancellationReason">Reason for Cancellation:</label>
                    <select
                        id="cancellationReason"
                        name='cancel-reason'
                        className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    >
                        <option value="">-- Select Reason --</option>
                        <option value="changedMind">Changed Mind</option>
                        <option value="betterPriceElsewhere">Found a better price elsewhere</option>
                        <option value="Productnolongerneeded">Product no longer needed</option>
                        <option value="orderedByMistake">Ordered by mistake</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                {reason === 'other' && (
                    <div className="mb-4">
                        <label htmlFor="otherReason">Other Reason:</label>
                        <input
                            type="text"
                            id="otherReason"
                            name='other-reason'
                            className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                        />
                    </div>
                )}

                <div className="flex justify-end mt-4">
                    <button
                        name='cancel'
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 duration-300 mr-2"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        name='yes-want-to-cancel'
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 duration-300"
                        onClick={handleCancelOrder}
                    >
                        Yes, I want to Cancel Order
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CancelOrder;
