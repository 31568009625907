import axios from "axios"
import { SEARCH_FAILED, SEARCH_REQUEST, SEARCH_SUCCESS } from "../constants/searchConstant";

const API = process.env.REACT_APP_API_URL

export const searchProduct = (searchText) => async(dispatch) => {
    try{

        dispatch({type: SEARCH_REQUEST})

        const reqData = {
            operation: "search",
            searchText: searchText,
        }

        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })

        dispatch({
            type: SEARCH_SUCCESS,
            payload: data,
        })
    }catch(error){
        console.log("Error in searchProduct: ",error);
        dispatch({
            type: SEARCH_FAILED,
            payload: error.response.data.message,
        })
    }
}