import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { getCartItems } from '../../actions/cartAction';
import { getShippingAddress } from '../../actions/shippingAction';

const Stepper = ({activeStep, children}) => {
    


  const dispatch = useDispatch();

    const {user} = useSelector((state) => state.user)

    useEffect(() => {
      if(user?.id){
        const userId = user.id;
        dispatch(getCartItems(userId))
        dispatch(getShippingAddress(userId))
      }
    }, [dispatch, user?.id])
    const { cartData, } = useSelector(state => state.cart)

    const { deliveryDetails:address } = useSelector(state => state.shippingAddress)
    const deliveryAddress = JSON.parse(localStorage.getItem('deliveryDetails'))

    const steps = [
      {
        label: 'LOGIN',
        desc: (
          <p className="font-bold text-gray-700">
            {user.name} <span className="font-medium text-gray-500">{user.email}</span>
          </p>
        ),
      },
      {
        label: 'DELIVERY ADDRESS',
        desc: (
          <p className="font-bold text-gray-700">
            {user.name} <span className="font-medium text-gray-500">{deliveryAddress ? deliveryAddress.address : address && address.address}</span>
          </p>
        ),
      },
      {
        label: 'ORDER SUMMARY',
        desc: (
          <p className="font-bold text-gray-700">
            {cartData && cartData.length} Item{cartData && cartData.length !== 1 ? 's' : ''}
          </p>
        ),
      },
      {
        label: 'PAYMENT OPTIONS',
        desc: <p className="font-bold text-gray-700">COD</p>,
      },
    ];
    
  return (

    <div className="flex flex-col gap-6">
    {steps.map((step, index) => (
      <React.Fragment key={index}>
        {activeStep === index ? (
          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="bg-green-500 text-white px-8 py-4 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <span className="h-8 w-8 flex items-center justify-center text-base font-bold bg-white rounded-full text-primary-blue">
                  {index + 1}
                </span>
                <h2 className="font-bold text-xl">{step.label}</h2>
              </div>
              {activeStep > index && (
                <span className="text-primary-blue">
                  <CheckIcon sx={{ fontSize: '24px' }} />
                </span>
              )}
            </div>
            <div className="p-0">{children}</div>
          </div>
        ) : (
          <>
            {activeStep > index ? (
              <Step isDesc={true} {...step} index={index} />
            ) : (
              <Step isDesc={false} {...step} index={index} />
            )}
          </>
        )}
      </React.Fragment>
    ))}
    </div>
  )
}

const Step = ({ isDesc, label, desc, index }) => {
    return (
      <div className="bg-white shadow-xl rounded-lg px-8 py-6 flex items-center justify-between overflow-x-auto">
        <div className="flex items-center gap-4">
          <span className="h-8 w-8 flex items-center justify-center text-base font-bold bg-gray-100 rounded-full text-primary-blue">
            {index + 1}
          </span>
          <h2 className="font-bold text-gray-800 flex items-center gap-2">
            {label}
            {isDesc && (
              <span className="text-primary-blue">
                <CheckIcon sx={{ fontSize: '24px' }} />
              </span>
            )}
          </h2>
        </div>
        {isDesc && (
          <div className="text-gray-600 font-medium">{desc}</div>
        )}
      </div>
    );
  };


export default Stepper;