import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'


const ProtectedRoute = ({children}) => {
 
  const { loading, isAuthenticated } = useSelector(state => state.user)

  return (

    <div>
      {loading === false && (
        isAuthenticated === false ? <Navigate to="/login" /> : children
      )}
    </div>
  )
}

export default ProtectedRoute
