import { FAILED_TO_GET_SHIPING_ADDRESS, FAILED_TO_SAVE_SHIPPING_ADDRESS, REQUEST_TO_GET_SHIPPPING_ADDRESS, SAVE_SHIPPING_ADDRESS_SUCCESS, SELECT_SHIPPING_ADDRESS, SET_DELIVERY_DETAILS, SUCCESS_TO_GET_SHIPING_ADDRESS } from "../constants/shippingConstants"

const initialState = {
    shippingAddress: [],// JSON.parse(localStorage.getItem('allShippingAddress')) || [],
    deliveryDetails: {
        address: '',
        mobileNo: ''
    },
    // mobileNo: null,
    loading: false,
    error: null
}

export const shippingAddressReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case SAVE_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.message,
                shippingAddress:[...state.shippingAddress, { id: new Date().getTime(), address: payload.address, mobile_no: payload.mobile_no }],
                // mobileNo: payload.mobile_no,
                error: null
            }
        case  FAILED_TO_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                loading: false,
                message: null,
                error: payload
            }
        case REQUEST_TO_GET_SHIPPPING_ADDRESS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SUCCESS_TO_GET_SHIPING_ADDRESS:
            return {
                ...state,
                loading: false,
                shippingAddress: payload,
                error: null
            }
        case FAILED_TO_GET_SHIPING_ADDRESS:
            return {
                loading: false,
                shippingAddress: null,
                error: payload
            }
        
        case SELECT_SHIPPING_ADDRESS:
            return{
                ...state,
                selectedAddress: payload
            }
        case SET_DELIVERY_DETAILS:
            return {
                ...state,
                deliveryDetails:{
                    address: payload.address,
                    mobileNo: payload.mobileNo
                }
            }
        default: 
            return state;
    }
}