import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { clearErrors, getUserDashboardData } from '../../actions/dashboardAction';
import Loader from '../Loader';
import { formatCurrency, slugify, stockStatus } from '../../utils/functions';
import { addToWishList, getWishlistItems, removeItemFromWishlist } from '../../actions/wishlistAction';
import MetaData from '../MetaData';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import defaultImg from "../../assets/images/defaultImage.jpg";
import { Tooltip } from "@mui/material";
import { addItemsToCart, getCartItems } from '../../actions/cartAction';

const ProductsByCategory = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { dashboardData, loading: dashboardLoading, error: dashboardError } = useSelector(state => state.dashboard);
    
    const { wishlistItems } = useSelector(state => state.wishlist)
    const { isAuthenticated, user }  = useSelector(state => state.user)

    const { categoryName } = useParams();

    const addToWishlistHandler = async(productId) => {
      if(!isAuthenticated){
        enqueueSnackbar("Please login to add products to your wishlist.", {variant:"warning"})
        return;
      }
      if(isAuthenticated  && user?.id){
        const userId = user.id;
        if(wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === productId)){
          await dispatch(removeItemFromWishlist(userId, productId))
          enqueueSnackbar('Item removed from wishlist', {variant:"success"})
        }else{
          await dispatch(addToWishList(userId, productId))
          enqueueSnackbar('Item added to wishlist', {variant:"success"})
        }
        await dispatch(getWishlistItems(userId))
      }
    }

    useEffect(() => {
      if(dashboardError){
        enqueueSnackbar(dashboardError, {variant:"error"})
        dispatch(clearErrors())
      }
      dispatch(getUserDashboardData())
      if(isAuthenticated && user?.id){
        const userId = user.id;
        dispatch(getWishlistItems(userId))
        dispatch(getCartItems(userId))
      }
      window.scrollTo(0,0)
    }, [dispatch, dashboardError, enqueueSnackbar, isAuthenticated, user?.id])

    const handleAddToCart = async(productId) => {

      if(!isAuthenticated ){
        return enqueueSnackbar("Please login to add products to your cart.", {variant: 'warning'})
      }

      if(isAuthenticated && user?.id){
        const userId = user?.id;

        await dispatch(addItemsToCart(userId, productId))
        await dispatch(getCartItems(userId))
        enqueueSnackbar("Item added to cart", {variant:"success"})
      }
    }

    const findCategory = dashboardData && dashboardData.categories && dashboardData.categories.length > 0 && dashboardData?.categories.find(category => slugify(category.category_name) === categoryName)
    
    if(!findCategory){
      return dashboardLoading || !findCategory ? <Loader/> : findCategory
    }
    const filteredProducts = dashboardData && dashboardData.inventory && dashboardData.inventory.length > 0 && dashboardData?.inventory.filter(product => product.category_id === findCategory.category_id)

  return (

    <div className='bg-white'>
      <div>
        <MetaData 
          title={`Trustyindian | Buy Indian ${findCategory?.category_name} Online at Best Price in Riga `} 
          description={`Trustyindian - Buy the best ${findCategory.category_name} online at the best prices in Riga. Our selection includes the finest Indian namkeen, sweets, khakhra and many more items. So order now and enjoy fast delivery!`}
          // image={}
          canonical={window.location.href}
          keywords={`Trustyindian, Trusty Indian, trusty indian, buy Indian ${findCategory?.category_name} Online in Riga Latvia, Indian Food in Riga, indian food in riga Indian Namkeen in Riga, Indian Sweets in Riga, Indian Ready to Eat in Riga, Order Surati Namkeen Online, Best Indian snacks in Riga Latvia, Where to buy Indian Khakhra in Riga, Order Indian snacks in Riga, Indian Khakhra in Riga, Surati Khakhra in Riga, Online khakhra store, Order Indian Breakfast in Riga, Where to buy Soan papdi in Riga, son papdi in Riga, Indian local food in Riga or Europe, surati local food in Riga, only street food in Riga, affordable grocery nearby me, Where to buy Khakra in Europe, Surti khkhra in Riga or Europe, indian food in riga, indian food near me `}
        />
        {dashboardLoading ? <Loader/> : (
          <div className="w-5xl px-4 pb-4 sm:pb-5">
            <h1 className="text-3xl font-semibold mb-3 pt-0">
              <span className="text-blue-500">{findCategory?.category_name}</span>
            </h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 px-1">
              {filteredProducts && filteredProducts.length > 0 && filteredProducts.map((product) => (
                <div key={product.product_id} className="bg-gray-50 lg:bg-white shadow-md overflow-hidden border rounded-lg cursor-pointer hover:-translate-y-2 transition-all relative">
                  <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute -top-1 -right-1">
                    <button
                      name="wishlist"
                      className={`absolute top-2 right-2 ${wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === product.product_id) ? 'text-red-500' : 'text-black'}`}
                      onClick={() => addToWishlistHandler(product.product_id)}
                    >
                        {wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === product.product_id) ? (
                          <Tooltip title="Remove from Wishlist" arrow>
                            <div>
                              <FavoriteIcon />
                            </div>
                          </Tooltip>
                            
                        ) : (
                          <Tooltip title="Add to Wishlist" arrow>
                            <div>
                              <FavoriteBorderIcon />
                            </div>
                          </Tooltip>
                        )}
                    </button>
                  </div>
                  <Link to={`/category/${slugify(findCategory.category_name)}/products/${slugify(product.product_name)}/${product.product_id}/buy`} className="cursor-pointer"> 
                    <div className="w-full h-40 pt-4 p-2 flex items-center justify-center mx-auto">
                      <img 
                        src={product.product_img1}
                        alt={product.product_name} 
                        loading="lazy"
                        title={product.product_name}
                        onError={(e) => e.target.src = defaultImg}
                        className="h-full w-full object-fill lg:object-contain rounded-md"
                      />
                    </div>
      
                    <div className="p-2 px-4">
                      <h3 className="text-xs md:text-md lg:text-lg font-semibold text-gray-800 overflow-hidden hover:text-blue-500 text-pretty" style={{ height: '55px', overflow: 'hidden',}}>{product.product_name}</h3>
                      <h4 className="text-xs md:text-md lg:text-lg text-gray-800 font-semibold mt-1.5">{formatCurrency(product.product_new_price)}</h4>
                    
                    </div>
                  </Link>
                  <div className='flex space-x-2 mt-3'>
                    {product.stock > 0 ? (
                      <button
                        type='button'
                        name='add-to-cart'
                        id='addtocart'
                        onClick={() => handleAddToCart(product.product_id)}
                        className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600"
                      >
                        <div className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-primary-lightGreen group-hover:text-white">Add to Cart</div>
                      </button>
                    ) : (
                      <div className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600">
                        <p className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:text-white cursor-default">{stockStatus(product.stock)}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductsByCategory
