import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { googleLogin } from '../../actions/userAction';


const GoogleSignIn = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLoginHook = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(googleLogin(response))
      navigate('/')
    },
    onError: (error) => console.log('Login Failed:', error)
  })

  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if(isAuthenticated){
      navigate("/")
    }
  }, [isAuthenticated, navigate])
   
  const handleGoogleSignIn = async() => {
    try{
      googleLoginHook();
    }catch(error){
      console.error('Error while Google sign-in:', error);
    }
  }

  return (
    <div>
      {/* {isAuthenticated  &&   */}
        <button className="bg-gray-900 flex items-center gap-2 justify-center w-full text-white py-2 rounded-lg hover:bg-black focus:outline-none focus:ring focus:ring-blue-500" 
          onClick={handleGoogleSignIn}
        >
          <img
            src="https://www.svgrepo.com/show/475656/google-color.svg"
            alt="Google"
            className="h-6 w-6"
          />
          Continue with Google
        </button>
      {/* } */}
    </div>
  )
}

export default GoogleSignIn;
