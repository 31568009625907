import { useDispatch, useSelector } from "react-redux"

import React from 'react'
import { Link } from "react-router-dom";
import MetaData from "../MetaData";
import { useSnackbar } from 'notistack';
import { addToWishList, getWishlistItems, removeItemFromWishlist } from "../../actions/wishlistAction";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import defaultImg from "../../assets/images/defaultImage.jpg";
import { Tooltip } from "@mui/material";
import { formatCurrency, slugify, stockStatus } from "../../utils/functions";
import { addItemsToCart, getCartItems } from "../../actions/cartAction";

const ProductList = ({products}) => {
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { wishlistItems } = useSelector(state => state.wishlist)
  const { isAuthenticated, user }  = useSelector(state => state.user)

  const addToWishlistHandler = async(productId) => {
    if(!isAuthenticated){
      enqueueSnackbar("Please login to add products to your wishlist.", {variant:"warning"})
      return;
    }
    if(isAuthenticated  && user?.id){
      const userId = user.id;
      if(wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === productId)){
        await dispatch(removeItemFromWishlist(userId, productId))
        enqueueSnackbar('Item removed from wishlist', {variant:"success"})
      }else{
        await dispatch(addToWishList(userId, productId))
        enqueueSnackbar('Item added to wishlist', {variant:"success"})
      }
      await dispatch(getWishlistItems(userId))
    }
  }

  const handleAddToCart = async(productId) => {
    if(!isAuthenticated){
      return enqueueSnackbar("Please login to add products to your cart.", {variant: 'warning'})
    }
    if(isAuthenticated && user?.id){
      const userId = user?.id;

      await dispatch(addItemsToCart(userId, productId))
      await dispatch(getCartItems(userId))
      enqueueSnackbar("Item added to cart", {variant:"success"})
    }
  }

  return (

    <div className="pb-4">
      <MetaData 
        title="Trustyindian | Buy Variety of Namkeen, Sweets and many more items Online in Riga Latvia - Indian food in Riga" 
        description={`Trustyindian - Buy the best Indian food online at the best prices in Riga. Our selection includes the finest Indian namkeen, sweets, khakhra and many more items. So order now and enjoy fast delivery!`}
        // image={}
        canonical={window.location.href}
        keywords={`Trustyindian, Trusty Indian, trusty indian, Buy Indian Namkeen Onlline in Riga or Europe, Buy Indian Sweets Onlline in Riga or Europe, Buy Indian Ready to Eat item Online in Riga or Europe, Surati Khakhra in Riga, Surati streetfood in riga, indian local food near me`}
      />
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6  px-4">
        {products && products?.map((product) => (

          <div key={product.product_id} className="bg-gray-50 lg:bg-white shadow-md overflow-hidden border rounded-lg cursor-pointer hover:-translate-y-2 transition-all relative">
            <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute -top-1 -right-1">
              <button
                name="wishlist"
                className={`absolute top-2 right-2 ${wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === product.product_id) ? 'text-red-500' : 'text-black'}`}
                onClick={() => addToWishlistHandler(product.product_id)}
              >
                  {wishlistItems && wishlistItems.length > 0 && wishlistItems.some(item => item.product_id === product.product_id) ? (
                    <Tooltip title="Remove from Wishlist" arrow>
                      <div>
                        <FavoriteIcon />
                      </div>
                    </Tooltip>
                      
                  ) : (
                    <Tooltip title="Add to Wishlist" arrow>
                      <div>
                        <FavoriteBorderIcon />
                      </div>
                    </Tooltip>
                  )}
              </button>
            </div>
            <Link to={`/products/${slugify(product.product_name)}/${product.product_id}/buy`} className="cursor-pointer"> 
              <div className="w-full h-40 pt-4 flex items-center justify-center p-2 overflow-hidden mx-auto">
                <img 
                  src={product.product_img1}
                  alt={product.product_name} 
                  loading="lazy"
                  title={product.product_name}
                  onError={(e) => e.target.src = defaultImg}
                  className="h-full w-full object-fill lg:object-contain rounded-md"
                />
              </div>

              <div className="p-2 px-4">
                <h3 className="text-xs md:text-md lg:text-lg font-semibold text-gray-800 overflow-hidden hover:text-blue-500 text-pretty" style={{ height: '55px', overflow: 'hidden',}}>{product.product_name}</h3>
                <h4 className="text-xs md:text-md lg:text-lg text-gray-800 font-semibold mt-2">{formatCurrency(product.product_new_price)}</h4>
               
                {/* <div className="flex space-x-2 mt-3">
                  <p className="text-gray-600 px-2.5 py-1.5 bg-gray-100 text-md rounded-xl flex items-center" >{stockStatus(product.stock)}</p>
                </div> */}
              </div>
            </Link>
            <div className="flex space-x-2 mt-3">
                {product.stock > 0 ? (
                  <button
                    type="button"
                    name="add-to-cart"
                    id="addtocart"
                    onClick={() => handleAddToCart(product.product_id)}
                    className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600"
                  >
                    <div className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-primary-lightGreen group-hover:text-white">Add to Cart</div>
                  </button>
                ) : (
                  <div className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600">
                    <p className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:text-white cursor-default">{stockStatus(product.stock)}</p>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>  
  )
}

export default ProductList;
