import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'

const DialogBox = ({dialogTitle, dialogMessage, open, handleClose, handleAction, cancelButtonName = "Cancel", buttonName}) => {
  return (

    <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="bg-gray-100 p-4 text-red-500"><span className='font-semibold'>{dialogTitle}!</span></DialogTitle>
        <DialogContent className="p-4">
            <p className="text-gray-700">{dialogMessage}</p>
        </DialogContent>

        <DialogActions className="bg-gray-100 p-4">
            <Button onClick={handleClose} variant="contained" color="secondary">{cancelButtonName}</Button>
            <Button onClick={handleAction} variant="contained" color="primary">{buttonName}</Button>
        </DialogActions>
      
    </Dialog>
  )
}

export default DialogBox;
