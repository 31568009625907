import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { searchProduct } from '../../actions/searchAction';
import { clearErrors } from '../../actions/productAction';
import ProductList from './ProductList';
import Loader from '../Loader';
import MetaData from '../MetaData';

const SearchResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { results, loading, error } = useSelector((state) => state.search);
    const location = useLocation()

    const [metaData, setMetaData] = useState("")
    
    useEffect(() => {
        if(error){
            enqueueSnackbar(error, {variant:"error"})
            dispatch(clearErrors())
        }
        const queryParams = new URLSearchParams(location.search)
        const searchText = queryParams.get("q")
        setMetaData(searchText)
        if(searchText){
            dispatch(searchProduct(searchText))
        }
    }, [dispatch, enqueueSnackbar, error, location.search])

  return (

    <div className="pt-0">
        <MetaData title={`Search: ${metaData}`} />
        {loading ? <Loader /> : (
            <div>
                {results && results.length > 0 ? (
                    <>
                        <h1 className="text-3xl font-semibold mb-3 px-4 pt-0 text-blue-500">Search Results</h1>
                        <ProductList products={results} />
                    </>
                ) : (
                    <div className="mt-0 h-72 md:h-80 lg:h-72 flex flex-col items-center justify-center gap-2 md:px-5">
                        <div className="max-w-lg text-center">
                            <h1 className="text-3xl font-semibold mb-3">No product found</h1>
                            <p className="text-gray-600 mb-3">Please check the spelling or try to search another product.</p>
                        </div>
                        <button
                            name='bo-back'
                            onClick={() => navigate(-1)}
                            className="bg-black text-white font-semibold py-3 px-8 rounded-md shadow hover:bg-gray-900 transition duration-300"
                        >
                            Go Back
                        </button>
                    </div>
                )}
            </div>
        )}
    </div>
  )
}

export default SearchResult
