import { Link } from 'react-router-dom';
import MetaData from './MetaData';

const NotFound = () => {
  return (

    <section className="bg-white dark:bg-gray-900">
      <MetaData title="404 Not Found"/>
      <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-6xl tracking-tight font-extrabold lg:text-7xl text-gray-900 dark:text-blue-500">404</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
          <Link 
            to="/" 
            className="inline-flex text-white bg-gray-900 hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-900 my-4"
          >
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NotFound;
