export const formatDate = (dt) => {
    return new Date(dt).toUTCString().substring(0,16)
}

export const getDiscount = (price, cuttedPrice) => {
    return (((cuttedPrice - price) / cuttedPrice) * 100).toFixed();
}

export const getDeliveryDate = () => {
    const deliveryDate = new Date();
    deliveryDate.setDate(new Date().getDate() + 3)
    return deliveryDate.toUTCString().substring(0, 11);
}

export const getShippedDate = () => {
  const shippedDate = new Date ();
  shippedDate.setDate(new Date().getDate() + 1)
  return shippedDate.toUTCString().substring(0,11)
}

export const getRandomProducts = (prodsArray, n) => {
    return prodsArray.sort(() => 0.5 - Math.random()).slice(0, n)
}

export const calculateDeliveryCharges  = () => {

    return 0;
}

export const calculateTaxAmount = (totalPrice, taxPercentage) => {
    const taxAmount = (totalPrice * (taxPercentage / 100));
    
    return taxAmount;
}

export const calculateTotalAmount = (totalPrice, taxPercentage) => {
    const deliveryCharges = calculateDeliveryCharges();
    const taxAmount = calculateTaxAmount(totalPrice, taxPercentage);
    const totalAmount = totalPrice + deliveryCharges + taxAmount;
    return totalAmount;
}

export const getStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case 'Pending':
        return 'bg-yellow-500';
      case 'Confirmed':
        return 'bg-blue-500';
      case 'Ready to Ship':
        return 'bg-green-500';
      case 'in Shipment':
        return 'bg-purple-500';
      case 'Out for Delivery':
        return 'bg-indigo-500';
      case 'Delivered':
        return 'bg-green-500';
      case 'Returned':
        return 'bg-yellow-600';
      case 'Canceled':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
};

export const formatCurrency = (price) => {

    const setCurrency = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
    }).format(price)

    return setCurrency;
}

export const formatPhoneNumber = (phoneNumber) => {
  if(!phoneNumber) return '';

  //Assuming country code is the first 3 characters
  const countryCode = phoneNumber.substring(0,4) //Update is country code length varies
  const localNumber = phoneNumber.substring(4)

  return `${countryCode} ${localNumber}`;
}

export const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')        // Remove all non-word chars except hyphen
    .replace(/--+/g, '-')           // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export const stockStatus = (stock) => {
  if(stock > 5){
    return <span className="text-green-600 font-semibold">In Stock</span>;
  } else if(stock > 0){
    return <span className="text-orange-600 font-semibold">Only few left</span>;
  } else {
    return <span className="text-red-600 font-semibold">Out of Stock</span>;
  }
}

export const handleShare = async(title, text, url, enqueueSnackbar) => {
  try{
    if(navigator.share){
      await navigator.share({
        title: title,
        text: `Take a look at this ${text}`,
        url: url
      });
      console.log('Thanks for sharing')
    }else{
      enqueueSnackbar('Web share is not supported in your browser.', { variant: "warning" });
    }
  }catch(error){
    if (error.name === 'AbortError' || error.message === 'Share canceled') {
      console.log('Share canceled by user');
    } else {
      console.log("Error during sharing page: ", error);
      enqueueSnackbar('Web share is not supported in your browser.', { variant: "warning" });
    }
  }
}