import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { resetPromoCode, validatePromoCode, clearErrors} from '../../actions/promoAction';

const PromoCode = ({ cartItems, }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    
    const { loading, promoCode:appliedPromoCode, promoCodeValid, error: promo_error, message } = useSelector(state => state.promo)
    const { user  } = useSelector(state => state.user);
    
    const [showPromoCode, setShowPromoCode] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoError, setPromoError] = useState('');
    const [promoSubmitted, setPromoSubmitted] = useState(false); // Track if a promo code submission was attempted


    
    useEffect(() => {
        if(promoSubmitted){

            if (promo_error) {
                enqueueSnackbar(promo_error, { variant: 'error' });
                setPromoError(promo_error);
                dispatch(clearErrors())
            }else if (message && promoCodeValid) {
                enqueueSnackbar(message, { variant: 'success' });
                // setPromoError(message);
                dispatch(clearErrors())
            } else if (message && !promoCodeValid) {
                enqueueSnackbar(message, { variant: 'error' });
                setPromoError(message);
                dispatch(clearErrors())
            }
        }
    }, [promoSubmitted, promo_error, dispatch, enqueueSnackbar, message, promoCodeValid ]);


    const handleShowPromoCode = () => {
        setShowPromoCode(true)
    }

    const handleCancelPromoCode = () => {
        setPromoCode('');
        dispatch(resetPromoCode())
        setShowPromoCode(false)
        // setShowError(false)
        setPromoError('');
        setPromoSubmitted(false);
    }

    const validatePromoCodeHandler = async () => {
        if (!promoCode) {
            return;
        }

        const totalPrice = cartItems.reduce((sum, item) => sum + (parseFloat(item.product_new_price) * parseInt(item.qty)), 0).toFixed(2);

        setPromoSubmitted(true);
        await dispatch(validatePromoCode(totalPrice, user?.id, promoCode));

    };

    const handlePromoCodeChange = (e) => {
        setPromoCode(e.target.value);
        // Reset showError when promo code changes
        // setShowError(false);
        setPromoError('');
        dispatch(resetPromoCode())
    };


  return (
    <div>
      {!showPromoCode && !appliedPromoCode  && (
        <p className='text-blue-600 cursor-pointer hover:text-blue-800 font-semibold ' onClick={handleShowPromoCode}>
            Click here to apply promo code
        </p>
      )}
      {showPromoCode && !appliedPromoCode  && (
        <div className='flex flex-col gap-2'>
            <TextField 
                label="Promo Code"
                variant='outlined'
                name='promo-code'
                autoComplete='off'
                value={promoCode}
                onChange={handlePromoCodeChange}
                InputProps={{
                    endAdornment: loading ? (
                        <InputAdornment position='end'>
                            <CircularProgress size={24} />
                        </InputAdornment>
                    ) : promoCodeValid ? (
                        <InputAdornment position='end'>
                            <IconButton position="end" disabled>
                                <CheckCircleOutline color='success' />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        promoCodeValid === false && promoError && (
                            <InputAdornment position='end'>
                                <IconButton position="end" disabled>
                                    <CancelOutlined className='text-red-500' />
                                </IconButton>
                            </InputAdornment>
                        )
                    )
                }}
            />
            {promoError && !loading && (
                <p className='text-red-600 font-semibold text-xs mb-2'>
                    {promoError}
                </p>
            )}
          
            <div className='flex gap-2 mb-2'>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={validatePromoCodeHandler}
                    // onClick={() => {
                    //     validatePromoCodeHandler();
                    //     setShowError(true); // Show error message on Apply button click
                    // }}
                    sx={{
                        color: "white",
                        fontSize: 15,
                        fontWeight: 'bold'
                    }}
                >Apply</Button>

                <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleCancelPromoCode}
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold"
                    }}
                >Cancel</Button>
            </div>
        </div>
      )}
      {appliedPromoCode && promoCodeValid && (
        <div className='flex items-center gap-2'>
            <p className='text-green-600 font-semibold'>
                {/* {message} */}
                Promo code applied successfully.
            </p>
            <Button
                variant='outlined'
                color='secondary'
                onClick={handleCancelPromoCode}
                sx={{
                    fontSize: 15,
                    fontWeight: "bold"
                }}
            >Cancel</Button>
        </div>
      )}
    </div>
  )
}

export default PromoCode
