import { Star, StarBorderOutlined, StarHalf } from "@mui/icons-material";

import React from 'react'

const RatingStar = ({rating}) => {
    const ratingStar = Array.from({length:5},(elem, index) => {
        let number = index + 0.5;
        return(
            <div key={index} className="text-yellow-500 text-2xl">
                {rating >= index + 1 ? (
                    <Star/>
                ) :  rating >= number ? (
                    <StarHalf />
                ): (
                    <StarBorderOutlined  />
                )}
            </div>
        )
    })
  return (
    <div className="flex gap-1 items-center justify-start">
      {ratingStar} 
    </div>
  )
}

export default RatingStar;
