import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import MetaData from '../MetaData';
import Loader from '../Loader';
import { getShippingAddress } from '../../actions/shippingAction';
import { formatPhoneNumber } from '../../utils/functions';

const Account = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, loading, isAuthenticated} = useSelector(state => state.user);
    const { shippingAddress, loading: shippingAddressLoading } = useSelector(state => state.shippingAddress)

    const [showMoreAddresses, setShowMoreAddresses] = useState(false);
    const [showMoreMobileNumbers, setShowMoreMobileNumbers] = useState(false);

    useEffect(() => {
        if(isAuthenticated && user?.id){
            const userId = user.id;
            dispatch(getShippingAddress(userId))
        }
        window.scrollTo(0,0)
    }, [dispatch, isAuthenticated, user?.id])
    
    if(!isAuthenticated){
        navigate("/login");
        return;
    }

    const renderMobileNumbers = () => {
        const numbersToShow = showMoreMobileNumbers ? shippingAddress : shippingAddress.slice(0, 2);
        return numbersToShow.map(address => (
            <div key={address.id} className="mb-4">
              <div className="flex items-center mb-2">
                <span className="text-primary-blue mr-2">&#8226;</span>
                <p className="text-sm font-semibold text-blue-900">{formatPhoneNumber(address.mobile_no)}</p>
              </div>
            </div>
        ));
    }

    const renderAddresses = () => {
        const addressesToShow = showMoreAddresses ? shippingAddress : shippingAddress.slice(0, 2);
        return addressesToShow.map(address => (
          <div key={address.id} className="mb-4">
            <div className="flex items-center mb-2">
              <span className="text-primary-blue mr-2">&#8226;</span>
              <p className="text-sm font-semibold text-blue-900">{address.address}</p>
            </div>
          </div>
        ));
    };
  return (


    <div className="bg-white min-h-screen pb-4">
    <MetaData title="My Profile" />

        {loading  || shippingAddressLoading? <Loader /> :
            <main className="mx-auto px-4">
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="bg-gradient-to-r from-green-400 to-purple-500 py-6 px-8">
                        <h1 className="text-3xl font-semibold text-white mb-4">Welcome  {user.name}</h1>
                        <p className="text-sm text-white">Manage your personal information, address, and more.</p>
                    </div>

                    <div className="mb-8 border-b pb-4 py-6 px-8">
                        <h2 className="text-lg font-semibold mb-3">Personal Information</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div>
                                <p className="text-md font-medium text-gray-600">First Name</p>
                                <p className="text-sm font-semibold text-blue-900">{user.name}</p>
                            </div>
                            <div>
                                <p className="text-md font-medium text-gray-600">Last Name</p>
                                <p className="text-sm font-semibold text-blue-900">{user?.family_name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-8 border-b pb-4 py-6 px-8">
                        <h2 className="text-lg font-semibold mb-3">Email Address</h2>
                        <p className="text-sm font-semibold text-blue-900">{user.email}</p>
                    </div>

                    <div className="mb-8 border-b pb-4 py-6 px-8">
                        <h2 className="text-lg font-semibold mb-3">Mobile Number</h2>
                        {shippingAddress && shippingAddress.length > 0 ? (
                            <div>
                                {renderMobileNumbers()}
                                {shippingAddress.length > 2 && (
                                    <button
                                        onClick={() => setShowMoreMobileNumbers(!showMoreMobileNumbers)}
                                        className="text-blue-500 font-semibold text-sm hover:text-blue-800"
                                    >
                                        {showMoreMobileNumbers ? 'See Less' : 'See More'}
                                    </button>
                                )}
                            </div>
                        ) : (
                            <div className="flex items-center mb-2">
                                <span className="text-primary-blue mr-2">&#8226;</span>
                                <p className="text-sm font-semibold text-blue-900">Mobile Number Not Available</p>
                            </div>
                        )}
                    </div>

                    <div className="mb-8 border-b pb-4 py-6 px-8">
                        <h2 className="text-lg font-semibold mb-3">Saved Address</h2>
                        {shippingAddress && shippingAddress.length > 0 ? (
                            <div>
                                {renderAddresses()}
                                {shippingAddress.length > 2 && (
                                    <button
                                        onClick={() => setShowMoreAddresses(!showMoreAddresses)}
                                        className="text-blue-500 font-semibold text-sm hover:text-blue-800"
                                    >
                                        {showMoreAddresses ? 'See Less' : 'See More'}
                                    </button>
                                )}
                            </div>
                            ) : (
                                <div className="flex items-center mb-2">
                                    <span className="text-primary-blue mr-2">&#8226;</span>
                                    <p className="text-sm font-semibold text-blue-900">Address Not Available</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </main>
        }
    </div>
  )
}

export default Account;
