import { SEARCH_FAILED, SEARCH_REQUEST, SEARCH_SUCCESS } from "../constants/searchConstant";


const initialState = {
    loading: false,
    results: [],
    error: null,
};

export const searchReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case SEARCH_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            }
        case SEARCH_SUCCESS:
            return{
                ...state,
                loading: false,
                results: payload,
                error: null,
            }
        case SEARCH_FAILED:
            return{
                ...state,
                loading: false,
                results: [],
                error: payload
            }
        default:
            return state;
    }
}