import React, { useEffect } from 'react'
import MetaData from '../MetaData'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { clearErrors, myOrders } from '../../actions/orderAction';
import Loader from '../Loader';
import OrderItem from './OrderItem';
import { useNavigate } from 'react-router-dom';
import NoOrders from './NoOrders';

const MyOrders = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar()

  const { myOrders:orders, loading, error } = useSelector(state => state.orders)

  const { isAuthenticated, user } = useSelector(state => state.user)

  if(!isAuthenticated){
    navigate("/login")
  }

  useEffect(() => {
    if(error){
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors())
    }
    dispatch(myOrders(user.id))

    window.scrollTo(0,0)
  }, [dispatch, error, enqueueSnackbar, user.id])

  return (
    <div>
      <MetaData title="My Orders" />
      <main className="w-full mt-6 sm:mt-0 px-2">

        {/* row */}
        <div className="flex gap-3.5 mt-2 sm:mt-6 sm:mx-3 mb-7">

          {/* orders column */}
          <div className="flex-1">
            {loading || !orders ? <Loader /> : (
              <div>
                {orders && orders.length > 0 ? (
                  <div className="flex flex-col gap-3 overflow-hidden">
                    {orders.map((order) => {                               
                      return  (
                        <OrderItem 
                          key={order.id}
                          orderItem={order}
                        />
                      )
                    }).reverse()}                        
                  </div>
                ): (
                  <NoOrders/>
                )}

              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

export default MyOrders
