import axios from "axios";
import { ALL_PRODUCTS_FAIL, ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_SUCCESS, CLEAR_ERRORS, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS } from "../constants/productConstants"

const API = process.env.REACT_APP_API_URL;

export const getProductList = () => async (dispatch) => {
    try{
        dispatch({ type: ALL_PRODUCTS_REQUEST });

        const reqBody = {
            operation: 'getDashboardData'
        }
        const { data } = await axios.post(API, reqBody,{
            headers:{
                "Content-Type":"application/json"
            }
        });

        dispatch({
            type: ALL_PRODUCTS_SUCCESS,
            payload: data,
        });

    }catch(error){
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error.response.data.message,
        })
    }
}

export const getSingleProduct = (id) => async(dispatch) => {
    try{
        dispatch({type: PRODUCT_DETAILS_REQUEST});
        
        const {data} = await axios.get(`${API}/${id}`)

        dispatch({
            type:PRODUCT_DETAILS_SUCCESS,
            payload: data
        })
    } catch(error){
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}