import axios from "axios"
import { ADD_TO_WISHLIST, ADD_TO_WISHLIST_FAILED, ADD_TO_WISHLIST_REQUEST, FAILED_TO_REMOVE_FROM_WISHLIST, REMOVE_FROM_WISHLIST, WISHLIST_DETAIL_FAILED, WISHLIST_DETAIL_REQUEST, WISHLIST_DETAIL_SUCCESS } from "../constants/wishlistConstants";

const API = process.env.REACT_APP_API_URL

export const addToWishList = (id, product_id) => async(dispatch) => {
    
    try{
        dispatch({type: ADD_TO_WISHLIST_REQUEST})

        const reqData = {
            operation: "AddOrRemoveWishlist",
            uid: id,
            product_id: product_id,
            is_wishlist: true
        }
        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        });
        dispatch({
            type: ADD_TO_WISHLIST,
            payload: {product_id, message: data.message}
        });
    
    }catch(error){
        console.log("Error in addToWishlist acton: ",error);
        dispatch({
            type: ADD_TO_WISHLIST_FAILED,
            payload: error.response.data.message
        })
    }

}

export const getWishlistItems = (id) => async(dispatch) => {
    try{
        dispatch({type: WISHLIST_DETAIL_REQUEST})

        const reqData = {
            operation: "getWishlist",
            uid: id
        }

        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })

        dispatch({
            type: WISHLIST_DETAIL_SUCCESS,
            payload: data
        })

        localStorage.setItem('wishlistItems', JSON.stringify(data))

    }catch(error){
        console.log("Error in getWishlistItems action: ",error)
        dispatch({
            type: WISHLIST_DETAIL_FAILED,
            payload: error.response.data.message
        })
    }
}

export const removeItemFromWishlist = (id, product_id) => async(dispatch) => {
    try{
        const reqData = {
            operation: "AddOrRemoveWishlist",
            uid: id,
            product_id: product_id,
            is_wishlist: false
        }

        const { data } = await axios.post(API, reqData, {
            headers:{
                "Content-Type": "application/json"
            }
        })
        dispatch({
            type:REMOVE_FROM_WISHLIST,
            payload: {product_id, message: data.message}
        })

        localStorage.removeItem('wishlistItems')
        
    }catch(error){
        console.log("error in removeItemFromWishlist action: ",error);
        dispatch({
            type: FAILED_TO_REMOVE_FROM_WISHLIST,
            payload: error.response.data.message
        })
    }

}