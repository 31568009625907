import { CLEAR_ERRORS, GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_USER_FAIL, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAIL, LOGOUT_USER_SUCCESS, } from "../constants/userConstants";


export const userReducer = (state = {user: {} }, {type, payload}) => {
    switch(type){
        case GOOGLE_LOGIN_REQUEST:
        case LOGIN_USER_REQUEST:
        // case LOAD_USER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false
            };
        case LOAD_USER_REQUEST:
            return {
                loading: false,
                isAuthenticated: false,
            }
        case GOOGLE_LOGIN_SUCCESS:
            return{
                ...state,
                loading:false,
                isAuthenticated:true,
                user: payload
            }
        case LOAD_USER_SUCCESS:
            return{
                ...state,
                loading:false,
                isAuthenticated:localStorage.getItem('token') ? true : false,
                user: payload
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: payload.data,
            };
        case LOGOUT_USER_SUCCESS:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
            };
        case LOGIN_USER_FAIL:
        case GOOGLE_LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: payload,
            };
        case LOAD_USER_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                error: payload,
            }
        case LOGOUT_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}