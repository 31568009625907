import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { clearErrors, getAllPromoCodes, myOrders } from '../../actions/orderAction';
import MetaData from '../MetaData';
import Loader from '../Loader';
import TrackStepper from "./TrackStepper";
import { calculateTaxAmount, formatCurrency, formatPhoneNumber, getDeliveryDate, getShippedDate, getStatusColor, slugify } from '../../utils/functions';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const {id} = useParams();

  const { myOrders:orders, loading, error, getAllPromoCode, } = useSelector(state => state.orders)
  const { taxPercentage, deliveryCharges } = useSelector(state => state.orders.priceBar)

  const { isAuthenticated, user } = useSelector(state => state.user)

  if(!isAuthenticated){
    navigate("/login");
  }
  useEffect(() => {
    if(error){
        enqueueSnackbar(error, {variant: "error"})
        dispatch(clearErrors)
    }
    dispatch(myOrders(user.id))
    dispatch(getAllPromoCodes())
    window.scrollTo(0,0)
  }, [dispatch, error, id, enqueueSnackbar, user.id])

  const orderedItem = orders && orders.length > 0 && orders.filter((i) => i.order_id === id.toString())

  return (
    <div>
      <MetaData title="Order Details" />

      <main className='w-full mt-0 sm:mt-4'>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {orderedItem && orderedItem.length > 0 && orderedItem.map((order) => {

              const { order_id, ordered_product_array, delivery_address, mobile_no, order_status, order_date, payment_mode, promo_code, total_amount } = order;
              
              const promoCode = getAllPromoCode && getAllPromoCode.length > 0 && getAllPromoCode.find((promo) => 
                promo.status === "valid" && promo.promo_code === promo_code
              )

              // const shipped_date = '2024-05-30 11:41:14.981182';
              // const delivered_date = '2024-05-20 11:41:14.981182';
              const shipped_date = getShippedDate();
              const delivered_date = getDeliveryDate();
              const orderProductArray = JSON.parse(ordered_product_array);

              // Calculate total amount
              const subtotal = orderProductArray.reduce((acc, item) => acc + (item.qty * item.product_new_price), 0);

              const isFreeShipping = deliveryCharges === 0;
              const displayShippingCharge = isFreeShipping ? formatCurrency(2.5) : formatCurrency(deliveryCharges)
              
              const discount = promoCode && promoCode.discount ? promoCode.discount : 0
              const discountAmount = subtotal * discount / 100;
              const discountedPrice = subtotal - (subtotal * (discount / 100))
              const taxAmount = calculateTaxAmount(discountedPrice, taxPercentage)
              
              return (
                <div key={order.order_id} className="bg-gray-100 rounded-lg shadow-lg pt-0 mb-6 p-2">
                  {/* Order ID row */}
                  <div className={`${getStatusColor(order_status)} text-white px-3 py-4 mb-4 rounded-lg flex justify-between items-center`}>
                    <p className="text-gray-200 font-bold">Order ID: {order_id}</p>
                    <span className="text-md text-white font-semibold py-1 px-3 rounded-full">{order_status}</span>
                  </div>

                  {/* Product section */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                    {orderProductArray && orderProductArray.length > 0 && orderProductArray.map((item) => (
                      <div key={item.product_id}>
                        <Link to={`/products/${slugify(item.product_name)}/${item.product_id}/buy`} className="block mb-6">
                          <div className="bg-white py-4 px-2 rounded-lg shadow-md mb-6 ">
                            <h3 className="text-lg font-semibold mb-2 text-gray-800 ">Product</h3>
                            <div className="flex gap-4 items-center bg-white p-4 rounded-lg shadow-md border border-gray-200">
                              <div className="w-32 h-32">
                                <img draggable="false" className="h-full w-full object-contain" src={item.product_img1} alt={item.product_name} title={item.product_name} />
                              </div>
                              <div>
                                <p className="text-md font-semibold">{item.product_name}</p>
                                <p className="text-sm text-gray-600 mt-1">Quantity: {item.qty}</p>
                                <p className="text-sm text-gray-600">Price: { formatCurrency(item.product_new_price)}</p>
                                <span className="text-base font-semibold">Total: { formatCurrency(item.qty * item.product_new_price)}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>

                  {/* Order summary  section*/}
                  <div className="mt-4 space-y-6 bg-white mb-6 shadow-md px-4 py-4 rounded-lg">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Order summary</h4>

                    <div className="space-y-4">
                      <div className="space-y-2">
                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-gray-600">Subtotal</dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">{formatCurrency(subtotal)}</dd>
                        </dl>

                        {order?.promo_code && 
                          <dl className="flex items-center justify-between gap-4">
                            <dt className="text-gray-600">Discount <span>{`(${promo_code} - ${discount}%)`}</span></dt>
                            <dd className="text-base font-medium text-gray-900 dark:text-white">{formatCurrency(discountAmount)}</dd>
                          </dl>
                        }
                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-gray-600">Tax ({taxPercentage}%)</dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">{formatCurrency(taxAmount)}</dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-gray-600">Shipping charge</dt>
                          <dd className={`text-base font-medium text-gray-900 dark:text-white ${isFreeShipping ? 'line-through decoration-2 decoration-red-500' : ''}`}>{displayShippingCharge}</dd>
                        </dl>

                        <hr className="my-2" />
                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-gray-600">Total amount</dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">{formatCurrency(total_amount)}</dd>
                        </dl>

                        {order?.promo_code && 
                          <dl className="flex items-center justify-between gap-4 mt-4">
                            <dt className="text-gray-600">Promo code</dt>
                            <dd className="text-base font-medium text-gray-900 dark:text-white">{promo_code}</dd>
                          </dl>
                        } 

                        <dl className="flex items-center justify-between gap-4 mt-3">
                          <dt className="text-gray-600">Payment method</dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">{payment_mode}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>

                  {/* Delivery Address section */}
                  <div className="bg-white p-3 rounded-lg shadow-md mb-6">
                    <h3 className="font-semibold mb-2 text-gray-800">Delivery Address</h3>
                    <p className="text-gray-600">{delivery_address}</p>
                    <p className='text-gray-600'>
                      <span className="font-semibold mb-2 text-gray-800">Mobile No:</span>
                      {formatPhoneNumber(mobile_no)}
                    </p>
                  </div>

                  {/* Order Status section */}
                <div className="bg-white p-3 rounded-lg shadow-md">
                  <h3 className="font-semibold mb-2 text-gray-800">Order Status</h3>
                  <div className="overflow-x-auto">
                    <TrackStepper
                      orderOn={order_date}
                      shippedAt={shipped_date}
                      deliveredAt={delivered_date}
                      orderStatus={order_status}
                      activeStep={
                        order_status === "Delivered" ? 5 : order_status === "Returned" || order_status === "Canceled" ? 7 : 
                        order_status === 'Out for Delivery' ? 4 : order_status === 'in Shipment' ? 3 : order_status === 'Ready to Ship' ? 2 :
                        order_status === 'Confirmed' ? 1 : 0
                      }
                    />
                  </div>
                </div>

                </div>
              )
            })}
          </div>
        )}
      </main>
    </div>
  )
}

export default OrderDetails
